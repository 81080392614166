import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgSavingAccountEuro = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M7.292 5.477c3.767-2.617 8.93-1.664 11.53 2.127a8.373 8.373 0 0 1 .796 8.02l.639.113A2.16 2.16 0 0 1 22 17.852a2.137 2.137 0 0 1-2.523 2.112 41.884 41.884 0 0 0-7.52-.66c-2.585 0-5.1.22-7.439.645A2.138 2.138 0 0 1 2 17.835c0-.98.658-1.822 1.584-2.078l.157-.037.626-.132c-1.504-3.567-.368-7.823 2.925-10.11zm11.859 11.08a8.272 8.272 0 0 1-1.722 2.081c.757.094 1.501.209 2.229.342A1.137 1.137 0 0 0 21 17.852c0-.51-.334-.951-.807-1.101l-.122-.031-.92-.163zm-14.325-.044l-.884.187A1.16 1.16 0 0 0 3 17.837c0 .718.64 1.255 1.34 1.128a39.46 39.46 0 0 1 2.23-.338 8.116 8.116 0 0 1-1.744-2.114zm13.17-8.343C15.71 4.835 11.174 3.998 7.863 6.299 4.55 8.6 3.716 13.17 6.003 16.507a7.295 7.295 0 0 0 1.88 1.878.5.5 0 0 1 .1.091 45.343 45.343 0 0 1 8.044.01l.041-.05.07-.058c3.314-2.301 4.146-6.873 1.859-10.208zm-5.908 4.117a.5.5 0 0 1 .09.992l-.09.008-3.688.001c.572 1.386 2.118 2.357 3.9 2.357 1.081 0 2.094-.356 2.857-.98a.5.5 0 1 1 .633.774c-.943.771-2.18 1.206-3.49 1.206-2.296 0-4.307-1.342-4.935-3.276a.503.503 0 0 1-.019-.082h-.82a.5.5 0 0 1-.09-.992l.09-.008h5.562zm.212-5.635c1.265 0 2.46.404 3.391 1.127a.5.5 0 0 1-.613.79c-.754-.586-1.734-.917-2.778-.917-1.715 0-3.213.898-3.831 2.202l-.085.193h3.704a.5.5 0 0 1 .492.411l.008.09a.5.5 0 0 1-.41.492l-.09.008H6.526a.5.5 0 0 1-.09-.992l.09-.008h.828a.5.5 0 0 1 .026-.164c.643-1.912 2.64-3.232 4.92-3.232z" />
		</svg>
	);
};

SvgSavingAccountEuro.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgSavingAccountEuro;
