import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import C from 'constants/Constants';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useDocumentsApi } from 'api/documents';

import { Row, Col } from 'components/Atoms/Grid';
import { Title, Text } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { MultipleFileUpload } from 'components/Atoms/Form';

const AddressVerification = () => {
	const { getCurrentFlow, isCreditSubType, isCreditCardSubType } = useUtils();
	const { focusFirstErrorInput, addErrorMessage } = useValidationUtils();
	const { goToPage, preventHistoryBackFor } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { getMissingDocuments, postForwardDocuments } = useDocumentsApi();

	const currentFlow = getCurrentFlow();

	const storeHasSecondApplicant = useSelector((state => state.secondApplicant.hasSecondApplicant));

	const [missingDocumentsDAC, setMissingDocumentsDAC] = useState([]);
	const [missingDocuments, setMissingDocuments] = useState([]);

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.ADDRESS_SELECTION);

		getMissingDocuments(
			missingDocumentsDAC,
			setMissingDocumentsDAC,
			missingDocuments,
			setMissingDocuments,
			1,
			true,
			getDestinationForFlow(),
			false
		);
	}, []);

	const getDestinationForFlow = () => {
		let destination;
		if (isCreditSubType()) {
			if (storeHasSecondApplicant) {
				destination = C.ROUTES.FINAL_STEPS_TWO_BORROWERS;
			} else {
				destination = C.ROUTES.LOAN_DONE;
			}
		} else if (
			currentFlow === C.FRONTEND.GIRO ||
			currentFlow === C.FRONTEND.DEPOT
		) {
			destination = C.ROUTES.THANKYOU;
		} else if (
			currentFlow === C.FRONTEND.GMK ||
			currentFlow === C.FRONTEND.SPARBRIEF
		) {
			destination = C.ROUTES.THANKYOU2;
		} else if (isCreditCardSubType()) {
			destination = C.ROUTES.ACTIVATE_CREDIT_CARD_ACCOUNT;
		}
		return destination;
	};

	const clearErrors = () => {};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (hasMissingDocuments()) {
			await addErrorMessage(FormErrorCode.EMPTY_FIELDS, m('pages.uploadDocuments.submitDisabledTooltip', 'global'));
			hasEmptyFields = true;
		}

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postForwardDocuments(
				() => {
					continueValid();
					goToPage(currentFlow + getDestinationForFlow());
				},
				() => {
					continueInvalid();
				}
			);
		} else {
			continueInvalid();
		}
	};

	const hasMissingDocuments = () => {
		let hasMissingsDocuments = false;

		if (missingDocumentsDAC && missingDocumentsDAC.length > 0) {
			missingDocumentsDAC.forEach(missingDocument => {
				if (
					!missingDocument.documentFiles ||
					(missingDocument.documentFiles &&
						missingDocument.documentFiles.length === 0)
				) {
					hasMissingsDocuments = true;
				}
			});
		}

		if (missingDocuments && missingDocuments.length > 0) {
			missingDocuments.forEach(missingDocument => {
				if (
					!missingDocument.documentFiles ||
					(missingDocument.documentFiles &&
						missingDocument.documentFiles.length === 0)
				) {
					hasMissingsDocuments = true;
				}
			});
		}

		return hasMissingsDocuments;
	};

	return (
		<>
			<Title mb>{m('addressVerification.title', currentFlow)}</Title>
			<Text size="l" mb>{m('addressVerification.subtitle', currentFlow)}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				onPrevButton={() => {
					continueValid();
					goToPage(currentFlow + C.ROUTES.ADDRESS_VERIFICATION_POSTPONED);
				}}
				prevButtonText={m('goOnLater.label', 'fields')}
				submitTabindex={40}
			>
				<Row justifyContent="center">
					{missingDocumentsDAC.map((item, index) => (
						<Col
							xs={12}
							md={4}
							key={'missing-documents-dac-' + index}
						>
							<div className="mb--20">
								<MultipleFileUpload
									index={index + 1}
									label={item.documentType}
									description={item.description}
									init={item.documentFiles ? item.documentFiles : []}
									missingDocuments={missingDocumentsDAC}
									setMissingDocuments={setMissingDocumentsDAC}
									allowJpg={true}
									allowPng={true}
									allowPdf={true}
									participant={1}
									required
								/>
							</div>
						</Col>
					))}

					{missingDocuments.map((item, index) => (
						<Col
							xs={12}
							md={4}
							key={'missing-documents-' + index}
						>
							<div className="mb--20">
								<MultipleFileUpload
									index={index + 1 + missingDocumentsDAC.length}
									label={item.documentType}
									description={item.description}
									init={item.documentFiles ? item.documentFiles : []}
									missingDocuments={missingDocuments}
									setMissingDocuments={setMissingDocuments}
									allowJpg={true}
									allowPng={true}
									allowPdf={true}
									participant={1}
									required
								/>
							</div>
						</Col>
					))}
				</Row>
			</Form>
		</>
	);
};
export default AddressVerification;
