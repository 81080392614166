import C from 'constants/Constants';
import { useUtils } from 'utils/utils';
import { useFlowUtils } from 'utils/flow/flow';

import { useSelector } from 'react-redux';

export const usePhoneUtils = () => {
	const { getCurrentFlow } = useUtils();
	const { auxMoneySanc, ergoSanc } = useFlowUtils();
	const currentFlow = getCurrentFlow();

	const storeSanc = useSelector((state => state.app.sanc));

	const splitMobileNumberAndCountryCode = (mobileNumber) => {
		if (mobileNumber.startsWith('+')) {
			return [
				removeNonDigitsAndLeadingZeroesFrom(mobileNumber.substring(0, 3)),
				removeNonDigitsAndLeadingZeroesFrom(
					mobileNumber.substring(3, mobileNumber.length)
				)
			];
		} else if (mobileNumber.startsWith('00')) {
			return [
				removeNonDigitsAndLeadingZeroesFrom(mobileNumber.substring(0, 5)),
				removeNonDigitsAndLeadingZeroesFrom(
					mobileNumber.substring(5, mobileNumber.length)
				)
			];
		} else if (mobileNumber.startsWith('0')) {
			return ['49', removeNonDigitsAndLeadingZeroesFrom(mobileNumber)];
		}
	};

	const removeNonDigitsAndLeadingZeroesFrom = (string) => {
		return parseInt(string.replace(/\D/g, ''), 10).toString();
	};

	const addSpaceAfterCountryCode = (mobileNumber) => {
		return mobileNumber.substring(0, 3) + ' ' + mobileNumber.substring(3);
	};

	const formatPhoneNumberForInput = (event, prevValue, ref, successCb) => {
		let formattedValue = event.target.value;
		if (formattedValue && formattedValue.length > 0) {
			formattedValue = formattedValue.trim();
			while (formattedValue && formattedValue.length > 0 && formattedValue.charAt(0) === '0') {
				formattedValue = formattedValue.substring(1);
			}
		}
		successCb(formattedValue);
	};

	const encryptPhoneNumber = (countryCode, mobileNumber) => {
		if (countryCode && mobileNumber) {
			return '+' + countryCode + 'xxxxx' + (mobileNumber + '').slice(-3);
		} else {
			return '';
		}
	};

	const getGermanPhonePrefixes = () => {
		return [
			'15019',
			'15020',
			'1511',
			'1512',
			'1514',
			'1515',
			'1516',
			'1517',
			'15180',
			'15181',
			'15182',
			'15183',
			'15184',
			'15185',
			'15186',
			'15310',
			'1520',
			'1521',
			'1522',
			'1523',
			'1525',
			'1526',
			'1529',
			'15510',
			'15511',
			'15560',
			'15561',
			'15562',
			'15563',
			'15564',
			'15565',
			'15566',
			'15567',
			'15568',
			'15569',
			'15630',
			'15678',
			'15679',
			'15700',
			'15701',
			'15702',
			'15703',
			'15704',
			'15706',
			'1573',
			'1575',
			'1577',
			'1578',
			'1579',
			'15888',
			'1590',
			'160',
			'162',
			'163',
			'170',
			'171',
			'172',
			'173',
			'174',
			'175',
			'176',
			'177',
			'178',
			'179'
		];
	};

	const hasGermanPhonePrefix = (number) => {
		let hasGermanPhonePrefix = false;
		let germanPhonePrefixes = getGermanPhonePrefixes();

		for (const prefix of germanPhonePrefixes) {
			if (number.startsWith(prefix)) {
				hasGermanPhonePrefix = true;
				break;
			}
		}

		return hasGermanPhonePrefix;
	};

	const getPhoneEndings = () => {
		switch (currentFlow) {
			case C.FRONTEND.BEST_CARD_BASIC:
			case C.FRONTEND.BEST_CARD_SMART:
			case C.FRONTEND.BEST_CARD_EXTRA:
			case C.FRONTEND.BEST_CARD_PREMIUM:
			case C.FRONTEND.VISACARD:
				return '115';
			case C.FRONTEND.CREDIT:
				if (storeSanc === '9300210806') {
					return '341';
				} else if (auxMoneySanc.includes(storeSanc) || ergoSanc.includes(storeSanc)) {
					return '325';
				}
				return '957';
			case C.FRONTEND.SHORT_CREDIT:
				if (storeSanc === '9103692100' || storeSanc === '9300440200' || storeSanc === '9300440300') {
					return '341';
				}
				return '223';
			default:
				return '599';
		}
	};

	const getPhoneNumber = () => {
		const ending = getPhoneEndings();
		if (currentFlow === C.FRONTEND.CREDIT) {
			if (storeSanc === '9100100100' || storeSanc === '9104181500') {
				return {
					link: '+4921616907057',
					text: '02161 - 69 07 057'
				};
			}
		}
		return {
			link: '+4921619060' + ending,
			text: '02161 - 90 60 ' + ending
		};
	};

	return {
		splitMobileNumberAndCountryCode,
		removeNonDigitsAndLeadingZeroesFrom,
		addSpaceAfterCountryCode,
		formatPhoneNumberForInput,
		encryptPhoneNumber,
		hasGermanPhonePrefix,
		getPhoneNumber
	};
};
