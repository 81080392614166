import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import C from 'constants/Constants';
import {
	CREDIT_AMOUNT_DEFAULT,
	CREDIT_AMOUNT_MAX_VALUE,
	CREDIT_AMOUNT_MIN_VALUE,
	CREDIT_RUNTIME_ITEMS
} from 'constants/Credit';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useNumberUtils } from 'utils/number/number';
import { useCreditUtils } from 'utils/credit/credit';
import { useApplicationApi } from 'api/application';

import { Text, Title } from 'components/Atoms/Text';
import Box from 'components/Molecules/Box/Box';
import { Col, Row } from 'components/Atoms/Grid';
import Button from 'components/Atoms/Button/Button';
import Form from 'components/Molecules/Form/Form';
import { DropdownInput, SliderInput } from 'components/Atoms/Form';
import Divider from 'components/Atoms/Divider/Divider';
import CreditDetails from 'components/Organisms/CreditDetails/CreditDetails';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import SvgEdit from 'components/Atoms/SVG/Icons/SvgEdit';
import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';
import SvgReload from 'components/Atoms/SVG/Icons/SvgReload';

import { useDispatch, useSelector } from 'react-redux';
import { setUpsellAlreadyDone } from 'store/Upsell/actions';
import { updateAmount, updateRuntime } from 'store/App/actions';

import styles from './Upsell.module.scss';

const Upsell = () => {
	const { getCurrentFlow } = useUtils();
	const { formatMoneyValue } = useNumberUtils();
	const { goToPage } = usePageUtils();
	const { continueValid } = useTrackingUtils();
	const { getMonthlyRate, getProtectMonthlyRateByProtect } = useCreditUtils();
	const { postApplication } = useApplicationApi();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeAmount = useSelector((state => state.app.amount));
	const storeRuntime = useSelector((state => state.app.runtime));

	const storeUpsellCreditAmountNet = useSelector((state => state.upsell.creditAmountNet));
	const storeUpsellRateMonthlyAmount = useSelector((state => state.upsell.rateMonthlyAmount));

	const storeUpsellRuntimeInMonths = useSelector((state => state.upsell.runtimeInMonths));
	const storeUpsellFixedBorrowingRate = useSelector((state => state.upsell.fixedBorrowingRate));
	const storeUpsellEffectiveAnnualInterestRate = useSelector((state => state.upsell.effectiveAnnualInterestRate));
	const storeUpsellInterestAmount = useSelector((state => state.upsell.interestAmount));

	const storeMaxUpsellAmount = useSelector((state => state.upsell.maxUpsellAmount));
	const storeMaxMonthlyRate = useSelector((state => state.upsell.maxMonthlyRate));

	const storeFirstRateDate = useSelector((state => state.app.firstRateDate));
	const storeProtect = useSelector((state => state.app.bcoResults?.protect));
	const storeProtectCoverage = useSelector((state => state.app.bcoResults?.protectCoverage));

	const [isEditViewOpen, setIsEditViewOpen] = useState(false);
	const [initialAmount] = useState(storeAmount);
	const [initialRuntime] = useState(storeRuntime);
	const [chosenAmount, setChosenAmount] = useState(storeUpsellCreditAmountNet);
	const [chosenRuntime, setChosenRuntime] = useState(storeUpsellRuntimeInMonths);

	const [submitted, setSubmitted] = useState(false);

	useEffect(() => {
		dispatch(setUpsellAlreadyDone(true));
	}, []);

	useEffect(() => {
		if (submitted) {
			if (initialAmount !== storeAmount || initialRuntime !== storeRuntime) {
				postApplication(
					() => {
						goToNextPage();
					},
					() => {},
					() => {},
					true
				);
			} else {
				dispatch(setUpsellAlreadyDone(true));
				goToPage(currentFlow + C.ROUTES.BCO_RESPONSE_GREEN);
			}
		}
	}, [submitted]);

	useEffect(() => {
		if (!isEditViewOpen) {
			onEditReset();
		}
	}, [isEditViewOpen]);

	const hasProtect = storeProtect && storeProtectCoverage;

	const calcMonthlyRate = useMemo(() => {
		if (
			storeUpsellRateMonthlyAmount &&
			chosenAmount === storeUpsellCreditAmountNet &&
			chosenRuntime === storeUpsellRuntimeInMonths
		) {
			return storeUpsellRateMonthlyAmount + (hasProtect ? getProtectMonthlyRateByProtect(storeProtect, storeProtectCoverage) : 0);
		}
		return getMonthlyRate(chosenAmount, chosenRuntime) + (hasProtect ? getProtectMonthlyRateByProtect(storeProtect, storeProtectCoverage) : 0);
	}, [chosenAmount, chosenRuntime]);

	const isMonthlyRateHigherThanMaxMonthlyRate = useMemo(() => {
		return storeMaxMonthlyRate && calcMonthlyRate > storeMaxMonthlyRate;
	}, [calcMonthlyRate, storeMaxMonthlyRate]);

	const totalAmount = useMemo(() => {
		return (storeUpsellCreditAmountNet ?? 0) +
			(storeUpsellInterestAmount ?? 0);
	}, [
		storeUpsellCreditAmountNet,
		storeUpsellInterestAmount
	]);

	const firstRateDate = useMemo(() => {
		return storeFirstRateDate
			? moment(storeFirstRateDate, 'DD.MM.YYYY').format('DD.MM.YYYY')
			: null;
	}, [storeFirstRateDate]);

	const goToNextPage = () => {
		continueValid();
		dispatch(setUpsellAlreadyDone(true));
		goToPage(currentFlow + C.ROUTES.BCO_RESPONSE_GREEN);
	};

	const onEditReset = () => {
		setChosenAmount(storeUpsellCreditAmountNet);
		setChosenRuntime(storeUpsellRuntimeInMonths);
	};

	const onSubmit = () => {
		if (!isMonthlyRateHigherThanMaxMonthlyRate) {
			if (chosenAmount === storeAmount && chosenRuntime === storeRuntime) {
				goToNextPage();
			} else {
				dispatch(updateAmount(chosenAmount));
				dispatch(updateRuntime(chosenRuntime));
				setSubmitted(true);
			}
		}
	};

	return (
		<>
			<Title mb>{m('pages.upsell.title', 'global')}</Title>
			<Text size="l" mb>{m('pages.upsell.subtitle', 'global', { amount: formatMoneyValue(storeUpsellCreditAmountNet ?? 0) })}</Text>

			{!isEditViewOpen ? (
				<>
					<Text size="l" mb><strong>{m('pages.upsell.offer.title', 'global')}</strong></Text>
					<Box shadow borderRadius>
						<Row>
							<Col xs={6}>
								<Text size="xl"><strong>{m('general.creditAmount', 'global')}:</strong></Text>
							</Col>
							<Col xs={6} textAlign="right">
								<Text size="xl"><strong><span className="text--highlight">{formatMoneyValue(storeUpsellCreditAmountNet ?? 0)} €</span></strong></Text>
							</Col>
						</Row>
						<Row>
							<Col xs={6}>
								<div className={styles.tooltipRow}>
									<Text size="l">
										{m(hasProtect ? 'rate.protectLong' : 'monthlyRate.label', 'fields')}:&nbsp;
									</Text>
									<TooltipIcon
										text={
											<>
												{m(
													'pages.upsell.offer.monthlyRateInfo',
													'global'
												)}
											</>
										}
										tabindex={1}
									/>
								</div>
							</Col>
							<Col xs={6} textAlign="right">
								<Text size="l"><strong>{formatMoneyValue((storeUpsellRateMonthlyAmount ?? 0) + (hasProtect ? getProtectMonthlyRateByProtect(storeProtect, storeProtectCoverage) : 0), true)} €</strong></Text>
							</Col>
						</Row>
						<Divider noLine noMarginBottom/>
						<CreditDetails
							monthlyRate={storeUpsellRateMonthlyAmount ?? 0}
							runtimeInMonths={storeUpsellRuntimeInMonths}
							effectiveAnnualInterestRate={storeUpsellEffectiveAnnualInterestRate}
							fixedBorrowingRate={storeUpsellFixedBorrowingRate}
							interest={storeUpsellInterestAmount}
							firstRateDate={firstRateDate}
							protect={storeProtect}
							protectCoverage={storeProtectCoverage}
							totalAmount={totalAmount}
						/>
					</Box>

					<div className="text-align-right mt--15">
						<Button
							onClick={() => {
								setIsEditViewOpen(true);
								setTimeout(() => {
									const focusArray = document.querySelectorAll('[tabindex="5"]');
									if (focusArray.length > 0) {
										focusArray[0].focus();
									}
								}, 1);
							}}
							buttonStyle="link"
							appendIcon={<SvgEdit />}
							tabindex={3}
						>
							Bearbeiten
						</Button>
					</div>
				</>
			) : (
				<>
					<Row>
						<Col>
							<Text size="l" mb><strong>{m('pages.upsell.edit.title', 'global')}</strong></Text>
						</Col>
						<Col textAlign="right">
							<button
								className={styles.closeIcon}
								onClick={() => {
									setIsEditViewOpen(false);
									setTimeout(() => {
										const focusArray = document.querySelectorAll('[tabindex="3"]');
										if (focusArray.length > 0) {
											focusArray[0].focus();
										}
									}, 1);
								}}
								tabIndex={4}
							>
								<SvgClose />
							</button>
						</Col>
					</Row>
					<Box shadow borderRadius>
						<Text size="l" mb>{m('pages.upsell.edit.subtitle', 'global')}</Text>

						<SliderInput
							value={chosenAmount ? `${chosenAmount}` : `${CREDIT_AMOUNT_DEFAULT}`}
							valueFormatter={(value) => `${value ? formatMoneyValue(value, false) : 0} €`}
							setValue={(value) => { setChosenAmount(value ? +value : '0'); }}
							label={m('general.creditAmount', 'global')}
							min={CREDIT_AMOUNT_MIN_VALUE}
							max={storeMaxUpsellAmount ?? CREDIT_AMOUNT_MAX_VALUE}
							step={500}
							tabindex={5}
							required
						/>

						<DropdownInput
							value={chosenRuntime ? `${chosenRuntime}` : null}
							setValue={(value) => { setChosenRuntime(+value); }}
							items={CREDIT_RUNTIME_ITEMS}
							label={m('wishRuntime.label', 'fields')}
							tabindex={7}
							tooltip={m('wishRuntime.tooltip', 'fields')}
							tooltipTabindex={6}
							required
						/>

						<Row>
							<Col xs={6}>
								<div className={styles.tooltipRow}>
									<Text size="l"><strong>{m(hasProtect ? 'rate.protectLong' : 'monthlyRate.label', 'fields')}:</strong></Text>
									<TooltipIcon
										text={m('pages.upsellDownsell.monthlyRateInfo', 'global')}
										tabindex={8}
									/>
								</div>
							</Col>
							<Col xs={6} textAlign="right">
								<Text size="l"><strong><span className={isMonthlyRateHigherThanMaxMonthlyRate ? 'text--error' : 'text--primary'}>{formatMoneyValue(calcMonthlyRate, true)} €</span></strong></Text>
								{isMonthlyRateHigherThanMaxMonthlyRate && (
									<Text size="s">
										<span className="text--error">
											{m(
												'pages.upsellDownsell.upsell.monthlyRateTooHigh',
												'global'
											)}
										</span>
									</Text>
								)}
							</Col>
						</Row>
					</Box>
				</>
			)}

			<Form
				onSubmit={onSubmit}
				clearErrors={() => {}}
				submitTabindex={9}
				submitText={isEditViewOpen ? m('save.label', 'fields') : null}
				onPrevButton={() => {
					if (isEditViewOpen) {
						onEditReset();
					} else {
						goToPage(currentFlow + C.ROUTES.BCO_RESPONSE_GREEN);
					}
				}}
				prevButtonText={isEditViewOpen ? m('reset.label', 'fields') : null}
				prevButtonIcon={isEditViewOpen ? <SvgReload /> : null}
				submitDisabled={isEditViewOpen && isMonthlyRateHigherThanMaxMonthlyRate}
			/>
		</>
	);
};
export default Upsell;
