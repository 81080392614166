import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgCheck = (props) => {
	const [titleId] = useState(uuid());
	const width = 12;
	const height = 9;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			<path d="M0.2788 3.63256C0.650533 3.26201 1.25323 3.26201 1.62496 3.63256L4.31729 6.31628L10.375 0.277908C10.7468 -0.0926363 11.3495 -0.092636 11.7212 0.277908C12.0929 0.648453 12.0929 1.24922 11.7212 1.61977L4.31729 9L0.2788 4.97442C-0.0929333 4.60387 -0.0929333 4.0031 0.2788 3.63256Z" />
		</svg>
	);
};

SvgCheck.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgCheck;
