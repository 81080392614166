import React, { useEffect } from 'react';
import classNames from 'classnames';
import { CREDIT_RUNTIME_ITEMS, CREDIT_RUNTIME_STEPS } from 'constants/Credit';
import { m } from 'utils/messages/messages';
import { useCreditUtils } from 'utils/credit/credit';

import { useDispatch, useSelector } from 'react-redux';
import { updateRuntime } from 'store/App/actions';

import styles from './CreditCalculator.module.scss';
import { DropdownInput } from '../../Atoms/Form';

const CreditCalculatorRuntime = () => {
	const { getValidRuntimeLimit } = useCreditUtils();

	const dispatch = useDispatch();
	const storeRuntime = useSelector((state => state.app.runtime));
	const storeRuntimeLimit = useSelector((state => state.app.runtimeLimit));
	const storeRuntimeNotification = useSelector((state => state.app.runtimeNotification));
	const storeSidebarExtensionActive = useSelector((state => state.app.sidebarExtensionActive));
	const storeSidebarRuntimeAnimation = useSelector((state => state.app.sidebarRuntimeAnimation));

	useEffect(() => {
		let maxRuntimeValueObj = getValidRuntimeLimit(storeRuntimeLimit);
		let maxRuntimeIndex = maxRuntimeValueObj.validRuntimeIndex;
		let maxRuntimeValue = maxRuntimeValueObj.validRuntimeValue;

		if (maxRuntimeIndex < CREDIT_RUNTIME_STEPS.indexOf(storeRuntimeLimit)) {
			dispatch(updateRuntime(maxRuntimeValue));
		}
	}, [storeRuntimeLimit]);

	return (
		<div
			className={classNames(styles.runtimeWrapper, {
				[styles.hideRuntimeWrapper]: storeSidebarExtensionActive,
				[styles.isOnTop]: storeSidebarRuntimeAnimation
			})}
		>
			<div className={styles.rangeSlider}>
				<DropdownInput
					value={storeRuntime ? `${storeRuntime}` : null}
					setValue={(value) => { dispatch(updateRuntime(+value)); }}
					items={CREDIT_RUNTIME_ITEMS}
					label={m('wishRuntime.label', 'fields')}
					tabindex={107}
					tooltip={m('wishRuntime.tooltip', 'fields')}
					tooltipTabindex={106}
				/>

				{storeRuntimeNotification && (
					<p className={styles.extraCopy}>
						{m('wishRuntime.extraCopy', 'fields')}
					</p>
				)}
			</div>
		</div>
	);
};
export default CreditCalculatorRuntime;
