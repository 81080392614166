import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgUploadToCloud = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M12.384 9.68l2.5 3.002a.5.5 0 0 1-.706.704l-.063-.064-1.616-1.94L12.5 21.5a.5.5 0 0 1-.41.492L12 22a.5.5 0 0 1-.492-.41l-.008-.09-.001-10.12-1.616 1.942a.5.5 0 0 1-.63.115l-.074-.05a.5.5 0 0 1-.115-.63l.05-.075 2.502-3.002.08-.077.037-.026.063-.034.067-.024.069-.014L12 9.5l.07.005.066.014.1.04.06.037.026.022.062.062zM9.975 4.294c1.17-1.763 3.445-2.647 5.646-2.163 2.354.517 4.022 2.466 4.004 4.718a4.554 4.554 0 0 1-.143 1.06c1.81.856 2.827 2.728 2.434 4.62-.423 2.03-2.349 3.481-4.57 3.471a.5.5 0 0 1 .005-1c1.76.008 3.263-1.125 3.586-2.675.32-1.539-.61-3.078-2.235-3.658a.5.5 0 0 1-.302-.644c.143-.386.219-.788.225-1.186.014-1.76-1.312-3.31-3.219-3.73-1.917-.42-3.893.422-4.765 2.016a.5.5 0 0 1-.608.23 2.875 2.875 0 0 0-.971-.167 2.597 2.597 0 0 0-1.784.64c-.463.414-.72.974-.724 1.613-.017.159-.017.32 0 .48a.5.5 0 0 1-.433.552c-1.886.241-3.232 1.754-3.114 3.454.12 1.705 1.672 3.055 3.58 3.075a.5.5 0 0 1-.01 1c-2.413-.026-4.41-1.762-4.567-4.005-.147-2.104 1.374-3.957 3.533-4.437.003-.063.008-.121.015-.175 0-.871.38-1.701 1.053-2.302a3.589 3.589 0 0 1 2.463-.895c.305 0 .608.037.901.108z" />
		</svg>
	);
};

SvgUploadToCloud.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgUploadToCloud;
