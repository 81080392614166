import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import C from 'constants/Constants';
import { C as Autocomplete } from 'constants/enums/Autocomplete';
import { C as FormErrorCode } from '../../../../constants/enums/FormErrorCode';
import { EMAIL_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useRegistrationApi } from 'api/registration';

import Form from 'components/Molecules/Form/Form';
import { Link, Text, Title } from 'components/Atoms/Text';
import { TextInput } from 'components/Atoms/Form';
import Autofill from 'components/Dev/Autofill/Autofill';
import Alert from 'components/Atoms/Alert/Alert';
import SvgEmailCheck from 'components/Atoms/SVG/Icons/SvgEmailCheck';
import SvgEmailResend from 'components/Atoms/SVG/Icons/SvgEmailResend';

import styles from '../Email/Email.module.scss';

const Login = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, hasEmailError, addErrorMessage } = useValidationUtils();
	const { postLogin, postResendInvitationEmail } = useRegistrationApi();

	const currentFlow = getCurrentFlow();

	const location = useLocation();

	const [showUnauthorizedWarning, setShowUnauthorizedWarning] = useState(false);
	const [showResendEmail, setShowResendEmail] = useState(false);
	const [showResendEmailSuccess, setShowResendEmailSuccess] = useState(false);

	const [email, setEmail] = useState(null);
	const [checkedEmail, setCheckedEmail] = useState(null);

	const [emailError, setEmailError] = useState(null);

	useEffect(() => {
		if (location.search !== undefined) {
			let search = new URLSearchParams(location.search);
			const redirect = search?.get('redirect');
			if (redirect === 'unauthorized') setShowUnauthorizedWarning(true);
		}
	}, []);

	const clearErrors = () => {
		setEmailError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isValidEmail = false;

		if (isEmpty(email)) {
			setEmailError(m('validation.error.requiredNamed', 'fields', { name: m('email.label', 'fields') }));
			hasEmptyFields = true;
		} else {
			isValidEmail = !hasEmailError(email);
			setEmailError(!isValidEmail ? m('validation.error.email.invalid', 'fields') : null);
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields && isValidEmail;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postLogin(
				email,
				() => {
					continueValid();
				},
				(status) => {
					if (status === 428) {
						continueInvalid();
						addErrorMessage(
							FormErrorCode.REGISTERED_BUT_NOT_VERIFIED,
							m('validation.error.registeredButNotVerified', 'fields')
						);
						setCheckedEmail(email);
						setShowResendEmail(true);
					} else {
						continueInvalid();
					}
				}
			);
		} else {
			continueInvalid();
		}
	};

	const resendEmail = async () => {
		await postResendInvitationEmail(
			() => {
				setShowResendEmailSuccess(true);
			},
			() => {},
			checkedEmail
		);
	};

	const autofill = () => {
		setEmail('niefilet@sales.de');
	};

	return (
		<>
			<Autofill autofill={autofill} />
			{showUnauthorizedWarning && (
				<Alert type="warning" messages={[{ message: m('pages.login.unauthorized.message', 'global') }]} />
			)}
			<Title mb>{m('pages.login.title', 'global')}</Title>
			<Text size="l" mb>{m('pages.login.subtitle', 'global')}</Text>

			{showResendEmail && checkedEmail && (
				<div className="text-align-right mb--10">
					<Text>
						{showResendEmailSuccess ? (
							<span className={classNames(['link primary', styles.resendLinkSuccess])}>
								<SvgEmailCheck />
								{m('pages.email.resendEmail.success', 'global')}
							</span>
						) : (
							<Link onClick={resendEmail}>
								<SvgEmailResend />
								{m('pages.email.resendEmail.link', 'global')}
							</Link>
						)}
					</Text>
				</div>
			)}

			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={2}
				onPrevButton={() => {
					goToPage(currentFlow + C.ROUTES.INDEX);
				}}
			>
				<TextInput
					value={email}
					setValue={setEmail}
					label={m('email.label', 'fields')}
					hasError={!!emailError}
					message={emailError}
					maxLength={50}
					regex={EMAIL_INPUT}
					autoComplete={Autocomplete.EMAIL}
					tabindex={1}
					testId="email"
					required
				/>
			</Form>
		</>
	);
};
export default Login;
