import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgAdd = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d='M12.002 4.5a1 1 0 0 1 .993.883l.007.117L13 11l5.5.002a1 1 0 0 1 .993.883l.007.117a1 1 0 0 1-.884.993l-.116.007L12.999 13v5.5a1 1 0 0 1-.884.993l-.117.007a1 1 0 0 1-.993-.884l-.007-.117L11 13h-5.5a1 1 0 0 1-.992-.884l-.007-.117a1 1 0 0 1 .884-.993l.116-.007H11l.002-5.499a1 1 0 0 1 .883-.993l.117-.006z' />
		</svg>
	);
};

SvgAdd.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgAdd;
