import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgUrgent = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10a.5.5 0 1 1 0-1c4.962 0 9-4.038 9-9s-4.038-9-9-9a.5.5 0 1 1 0-1zM9.5 19a.5.5 0 0 1 .09.992L9.5 20h-3a.5.5 0 0 1-.09-.992L6.5 19h3zm0-3a.5.5 0 0 1 .09.992L9.5 17h-6a.5.5 0 0 1-.09-.992L3.5 16h6zm0-3a.5.5 0 0 1 .09.992L9.5 14h-7a.5.5 0 0 1-.09-.992L2.5 13h7zm3-7a.5.5 0 0 1 .492.41L13 6.5V12h5.5a.5.5 0 0 1 .09.992L18.5 13h-6a.5.5 0 0 1-.492-.41L12 12.5v-6a.5.5 0 0 1 .5-.5zm-3 4a.5.5 0 0 1 .09.992L9.5 11h-7a.5.5 0 0 1-.09-.992L2.5 10h7zm0-3a.5.5 0 0 1 .09.992L9.5 8h-6a.5.5 0 0 1-.09-.992L3.5 7h6zm0-3a.5.5 0 0 1 .09.992L9.5 5h-3a.5.5 0 0 1-.09-.992L6.5 4h3z"/>
		</svg>
	);
};

SvgUrgent.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgUrgent;
