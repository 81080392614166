import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';

import { Text, Title } from 'components/Atoms/Text';
import ToggleLink from 'components/Atoms/Partials/ToggleLink/ToggleLink';

import styles from './BoxCollapsible.module.scss';

const BoxCollapsible = props => {
	const [active, setActive] = useState(!!props.showOnMount);

	return (
		<div
			id={props.ariaControls ?? null}
			role="dialog"
			className={
				classNames([
					styles.box,
					active ? styles.active : null,
					!!props.contentBody ? styles.hasBody : null,
					props.stretch ? styles.stretch : null
				])
			}
		>
			<div className={styles.header}>
				{props.icon && (
					<div className={styles.headerIcon}>
						{props.icon}
					</div>
				)}
				<div className={styles.headerContent}>
					<Title size="s" mb>{props.title}</Title>
					{props.subtitle && <Text size="m">{props.subtitle}</Text>}
					{props.contentHeader && (
						<div className={styles.headerContentExtension}>
							{props.contentHeader}
						</div>
					)}
				</div>
			</div>
			{props.contentBody && (
				<>
					<div className={styles.body}>
						<div className={styles.divider} />
						{props.contentBody}
					</div>
					<div className={styles.toggle}>
						<ToggleLink
							active={active}
							setActive={() => { setActive(!active); }}
							size="l"
							ariaLabel={props.ariaLabelActive && props.ariaLabelInactive ? (active ? props.ariaLabelActive : props.ariaLabelInactive) : null}
							ariaExpanded={active}
							ariaControls={props.ariaControls ?? null}
						>
							{active ? m('box.close', 'fields') : m('box.readMore', 'fields') }
						</ToggleLink>
					</div>
				</>
			)}
		</div>
	);

};

BoxCollapsible.propTypes = {
	icon: PropTypes.element,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	contentHeader: PropTypes.element,
	contentBody: PropTypes.element,
	showOnMount: PropTypes.bool,
	stretch: PropTypes.bool,
	ariaLabelActive: PropTypes.string,
	ariaLabelInactive: PropTypes.string,
	ariaControls: PropTypes.string.isRequired
};

export default BoxCollapsible;
