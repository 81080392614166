import React, { useEffect, useState } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useValidationUtils } from 'utils/validation/validation';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { usePageUtils } from 'utils/page/page';
import { useItemsUtils } from 'utils/items/items';

import { Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { DropdownInput } from 'components/Atoms/Form';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setNationality } from 'store/Nationality/actions';

const Nationality = () => {
	const { getCurrentFlow, isCreditCardSubType } = useUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { continueInvalid, continueValid, ecommerceBeginCheckout } = useTrackingUtils();
	const { goToPage } = usePageUtils();
	const { getNationalityItems } = useItemsUtils();

	const nationalityItems = getNationalityItems();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeNationality = useSelector((state => state.nationality.nationality));

	const [nationalityError, setNationalityError] = useState(null);

	useEffect(() => {
		ecommerceBeginCheckout();
	}, []);

	const clearErrors = () => {
		setNationalityError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(storeNationality)) {
			setNationalityError(m('validation.error.requiredNamed', 'fields', { name: m('nationality.label', 'fields') }));
			hasEmptyFields = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		if (currentFlow === C.FRONTEND.DEPOT && (storeNationality === 'RU' || storeNationality === 'BY')) {
			goToPage(currentFlow + C.ROUTES.THANKYOU3);
		} else if ((currentFlow === C.FRONTEND.DEPOT || isCreditCardSubType(currentFlow, [C.FRONTEND.VISACARD])) && storeNationality === 'US') {
			goToPage(currentFlow + C.ROUTES.NO_US);
		} else {
			goToPage(currentFlow + C.ROUTES.ADDRESS);
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		dispatch(setNationality('DE'));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.nationality.title', 'global')}</Title>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={2}
			>
				<DropdownInput
					value={storeNationality}
					setValue={(value) => { dispatch(setNationality(value)); }}
					items={nationalityItems}
					label={m('nationality.label', 'fields')}
					hasError={!!nationalityError}
					message={nationalityError}
					tabindex={1}
					testId="nationality"
					required
				/>
			</Form>
		</>
	);
};
export default Nationality;
