import React, { forwardRef, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { DEFAULT_INPUT } from 'constants/Regex';
import { useRefUtils } from 'utils/refs/refs';

import { Link } from 'components/Atoms/Text';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import Ripple from 'components/Atoms/Ripple/Ripple';
import HelperText from 'components/Atoms/Form/HelperText/HelperText';
import SvgVisibilityOff from 'components/Atoms/SVG/Icons/SvgVisibilityOff';
import SvgVisibilityOn from 'components/Atoms/SVG/Icons/SvgVisibilityOn';

import styles from './TextInput.module.scss';

export const TextInput = forwardRef((props, ref) => {
	const [id] = useState(uuid());
	const inputRef = ref ? ref : useRef(null);

	const { focusRef } = useRefUtils();

	const [prevValue, setPrevValue] = useState(props.value ?? '');
	const [pinShow, setPinShow] = useState(false);

	useEffect(() => {
		setPrevValue(props.value);
	}, [props.value]);

	const onChange = (event) => {
		if (props.format) {
			props.format(event, prevValue, ref, (formattedValue) => {
				onChangeValue(formattedValue);
			});
		} else {
			onChangeValue(event.target.value);
		}
	};

	const onChangeValue = (value) => {
		if (props.regex) {
			if (props.regex.test(value) || value === '') {
				props.setValue(value);
				jumpToNextOnMaxLength(value);
			}
		} else {
			if (DEFAULT_INPUT.test(value) || value === '') {
				props.setValue(value);
				jumpToNextOnMaxLength(value);
			}
		}
	};

	const jumpToNextOnMaxLength = (value) => {
		if (props.nextRefOnMaxLength && props.maxLength && value.length >= props.maxLength) {
			focusRef(props.nextRefOnMaxLength);
		}
	};

	const type = useMemo(() => {
		if (props.type === 'pin')
			return pinShow ? 'text' : 'password';
		return props.type ?? 'text';
	}, [props.type, pinShow]);

	const charCount = useMemo(
		() => (props.value && props.value.length > 0 ? props.value.length : 0),
		[props.value]
	);

	const pinLoop = useMemo(() => {
		if (props.type !== 'pin' || !props.maxLength) return [];
		const array = [];
		for (let i = 0; i < props.maxLength; i++) {
			array.push(0);
		}
		return array;
	}, [props.type, props.maxLength]);

	const pinFieldWidth = 2.15;

	const pinWidth = useMemo(
		() => pinLoop.length * pinFieldWidth + pinLoop.length * 0.5 - 0.5,
		[pinLoop]
	);

	const handleFocus = (event) => {
		if (props.preventFocusMark) {
			event.preventDefault();
			const input = inputRef.current;
			if (input) {
				input.selectionStart = input.selectionEnd = input.value?.length ?? 0;
			}
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.labelWrapper}>
				<label htmlFor={id} className={styles.label}>
					{props.label}{props.required ? '*' : ''}
				</label>
				{props.tooltip && (
					<span className={styles.tooltip}>
						<TooltipIcon
							text={props.tooltip}
							tabindex={props.tooltipTabindex}
						/>
					</span>
				)}
			</div>
			<div
				className={styles.inputWrapper}
				style={
					props.type === 'pin'
						? { width: pinWidth + pinFieldWidth + 1 + 'rem' }
						: null
				}
			>
				<input
					ref={inputRef}
					id={id}
					className={classNames(
						styles.input,
						props.suffix ? styles.hasSuffix : null,
						props.hasError ? styles.error : null,
						props.hasError ? 'input--error' : null,
						props.type === 'pin' ? styles.pin : null,
						props.value?.length === props.maxLength
							? styles.maxLengthReached
							: null
					)}
					value={props.value ?? ''}
					onChange={onChange}
					type={type}
					placeholder={props.placeholder}
					disabled={props.disabled}
					required={props.required}
					onInvalid={(event) => event.preventDefault()}
					maxLength={props.maxLength}
					pattern={
						props.type === 'number' || props.type === 'pin' ? '\\d*' : null
					}
					name={props.autoComplete ?? null}
					autoComplete={props.autoComplete ?? id ?? 'off'}
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
					style={
						props.type === 'pin'
							? { width: pinWidth + pinFieldWidth + 1 + 'rem' }
							: null
					}
					onFocus={handleFocus}
					aria-describedby={props.hasError ? `error-${id}` : null}
				/>
				{props.type !== 'pin' && (
					<div className={styles.ripple}>
						<Ripple rippleRef={inputRef} color='#cccccc' />
					</div>
				)}
				{props.suffix && <div className={styles.suffix}>{props.suffix}</div>}
				{props.type === 'pin' && props.maxLength > 0 && (
					<div className={styles.pinWrapper}>
						{pinLoop?.map((item, index) => (
							<Fragment key={id + '-pin-input-' + index}>
								<span
									className={classNames(
										styles.pinField,
										props.value?.length > index ? styles.hasContent : null,
										(!props.value && index === 0) ||
											props.value?.length === index ||
											(props.value?.length === props.maxLength &&
												index === props.maxLength - 1)
											? styles.pinFieldActive
											: null
									)}
								/>
								<span className={styles.pinSpace} />
							</Fragment>
						))}
					</div>
				)}
			</div>
			{(props.message || (props.maxLength && props.showMaxLength)) && (
				<div className={styles.messageRow}>
					<div className={styles.message}>
						<HelperText
							id={`error-${id}`}
							type={props.hasError ? 'error' : 'light'}
							message={props.message}
							hasIcon
						/>
					</div>
					{props.maxLength && props.showMaxLength && (
						<div className={styles.lengthCounter}>
							<HelperText
								message={
									<>
										{charCount}/{props.maxLength}
									</>
								}
								type={props.hasError ? 'error' : 'default'}
							/>
						</div>
					)}
				</div>
			)}
			{props.type === 'pin' && props.maxLength > 0 && !props.disabled && (
				<div
					className={styles.pinShowWrapper}
					style={props.type === 'pin' ? { width: pinWidth + 'rem' } : null}
				>
					<div className='text--primary text--size--m mt--5'>
						<Link
							color='secondary'
							onClick={() => {
								setPinShow(!pinShow);
							}}
							tabindex={props.tabindex ? props.tabindex + 1 : null}
							role="switch"
							ariaChecked={pinShow}
							ariaLabel={
								pinShow ? 'Zugangscode verstecken' : 'Zugangscode anzeigen'
							}
						>
							{pinShow ? (
								<>
									<span className={styles.pinShowButtonIcon}>
										<SvgVisibilityOff />
									</span>
									<span className={styles.pinShowButtonText}>Verstecken</span>
								</>
							) : (
								<>
									<span className={styles.pinShowButtonIcon}>
										<SvgVisibilityOn />
									</span>
									<span className={styles.pinShowButtonText}>Anzeigen</span>
								</>
							)}
						</Link>
					</div>
				</div>
			)}
		</div>
	);
});

TextInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	placeholder: PropTypes.string,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	maxLength: PropTypes.number,
	showMaxLength: PropTypes.bool,
	preventFocusMark: PropTypes.bool,
	nextRefOnMaxLength: PropTypes.any,
	autoComplete: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltipTabindex: PropTypes.number,
	suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	regex: PropTypes.any,
	format: PropTypes.func,
	testId: PropTypes.string
};

export default TextInput;
