import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useNumberUtils } from 'utils/number/number';

import { Text } from 'components/Atoms/Text';
import ToggleLink from 'components/Atoms/Partials/ToggleLink/ToggleLink';

import styles from './CreditDetails.module.scss';
import ProtectTarif from 'components/Atoms/Partials/Values/ProtectTarif';
import { useCreditUtils } from 'utils/credit/credit';

const CreditDetails = props => {
	const { formatMoneyValue } = useNumberUtils();
	const { getProtectMonthlyRateByProtect } = useCreditUtils();

	const [isCreditDetailsOpen, setIsCreditDetailsOpen] = useState(false);

	return (
		<>
			<ToggleLink
				active={isCreditDetailsOpen}
				setActive={() => {
					setIsCreditDetailsOpen(!isCreditDetailsOpen);
				}}
				size="l"
				ariaLabel={isCreditDetailsOpen ? 'Kreditdetails schließen' : 'Kreditdetails öffnen'}
				ariaControls="credit-details-wrapper"
				tabindex={2}
			>
				{m('details', 'fields')}
			</ToggleLink>
			<div
				id="credit-details-wrapper"
				role="dialog"
				className={classNames([
					styles.upsellDownsellMoreDetailsWrapper,
					isCreditDetailsOpen
						? styles.upsellDownsellMoreDetailsWrapperActive
						: null
				])}
			>
				<div className={styles.detailsTable}>
					{props.protect && props.protectCoverage && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								<Text size="s"><strong>{m('monthlyRate.labelLong', 'fields')}:</strong></Text>
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid="runtime"
							>
								<Text size="s">{`${formatMoneyValue(props.monthlyRate, true)} €`}</Text>
							</div>
						</div>
					)}

					{!!props.firstRateDate && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								<Text size="s"><strong>{m('firstRate.date', 'fields')}:</strong></Text>
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid="first-rate-date"
							>
								<Text size="s">{props.firstRateDate}</Text>
							</div>
						</div>
					)}

					<div className={styles.detailsTableRow}>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellLabel
							])}
						>
							<Text size="s"><strong>{m('runtime.label', 'fields')}:</strong></Text>
						</div>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellValue
							])}
							data-testid='runtime'
						>
							<Text size="s">{(props.runtimeInMonths ?? 0) + ' Monate'}</Text>
						</div>
					</div>

					{!!props.effectiveAnnualInterestRate && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								<Text size="s"><strong>{m('effectiveYearlyInterest.label', 'fields')}:</strong></Text>
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='effectiveAnnualInterestRate'
							>
								<Text size="s">{`${formatMoneyValue(props.effectiveAnnualInterestRate, true)} %`}</Text>
							</div>
						</div>
					)}

					{!!props.fixedBorrowingRate && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								<Text size="s"><strong>{m('fixedBorrowingRate.label', 'fields')}:</strong></Text>
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='fixed-borrowing-rate'
							>
								<Text size="s">{`${formatMoneyValue(props.fixedBorrowingRate, true)} %`}</Text>
							</div>
						</div>
					)}

					{!!props.interest && (
						<div className={styles.detailsTableRow}>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellLabel
								])}
							>
								<Text size="s"><strong>{m('interest.label', 'fields')}:</strong></Text>
							</div>
							<div
								className={classNames([
									styles.detailsTableCell,
									styles.detailsTableCellValue
								])}
								data-testid='interest'
							>
								<Text size="s">{`${formatMoneyValue(props.interest, true)} €`}</Text>
							</div>
						</div>
					)}

					<div className={styles.detailsTableRow}>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellLabel
							])}
						>
							<Text size="s">
								<strong>
									{m('totalAmount.label', 'fields')}:
								</strong>
							</Text>
						</div>
						<div
							className={classNames([
								styles.detailsTableCell,
								styles.detailsTableCellValue
							])}
							data-testid='total-amount'
						>
							<Text size="s">{`${formatMoneyValue(props.totalAmount, true)} €`}</Text>
						</div>
					</div>

					{props.protect && props.protectCoverage && (
						<>
							<div className={styles.detailsTableRow}>
								<div
									className={classNames([
										styles.detailsTableCell,
										styles.detailsTableCellLabel
									])}
								>
									<Text size="s"><strong>{m('protect.tarif.label', 'fields')}:</strong></Text>
								</div>
								<div
									className={classNames([
										styles.detailsTableCell,
										styles.detailsTableCellValue
									])}
									data-testid='protect'
								>
									<Text size="s">
										<ProtectTarif customProtect={props.protect} />
									</Text>
								</div>
							</div>

							<div className={styles.detailsTableRow}>
								<div
									className={classNames([
										styles.detailsTableCell,
										styles.detailsTableCellLabel
									])}
								>
									<Text size="s"><strong>{m('protect.coverage.label', 'fields')}:</strong></Text>
								</div>
								<div
									className={classNames([
										styles.detailsTableCell,
										styles.detailsTableCellValue
									])}
									data-testid='protect-coverage'
								>
									<Text size="s">
										{formatMoneyValue(props.protectCoverage, false)} €
									</Text>
								</div>
							</div>

							<div className={styles.detailsTableRow}>
								<div
									className={classNames([
										styles.detailsTableCell,
										styles.detailsTableCellLabel
									])}
								>
									<Text size="s"><strong>{m('protect.monthlyRate.label', 'fields')}:</strong></Text>
								</div>
								<div
									className={classNames([
										styles.detailsTableCell,
										styles.detailsTableCellValue
									])}
									data-testid='protect-coverage'
								>
									<Text size="s">
										{formatMoneyValue(getProtectMonthlyRateByProtect(props.protect, props.protectCoverage), true)} €
									</Text>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

CreditDetails.propTypes = {
	monthlyRate: PropTypes.number,
	runtimeInMonths: PropTypes.number,
	totalAmount: PropTypes.number,
	protect: PropTypes.string,
	protectCoverage: PropTypes.number,
	protectMonthlyRate: PropTypes.number,
	interest: PropTypes.number,
	firstRateDate: PropTypes.string,
	effectiveAnnualInterestRate: PropTypes.number,
	fixedBorrowingRate: PropTypes.number
};

export default CreditDetails;
