import * as React from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';

const SvgProtectShortTimeWork = (props) => {
	const [titleId] = useState(uuid());
	const width = 30;
	const height = 30;
	const style1 = { fill: '#9b3667' };
	const style2 = { fill: '#ffffff' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<rect style={style1} width="30" height="30" rx="4.62"/>
			<path style={style2} d="M24.15,6.72a.4.4,0,0,0-.39.38l0,2.25-.95.94a.39.39,0,0,0,0,.55.4.4,0,0,0,.27.11.43.43,0,0,0,.28-.11l1.05-1.06h0l0,0a.53.53,0,0,0,.06-.1.18.18,0,0,0,0-.1l0-.05h0l0-2.4A.38.38,0,0,0,24.15,6.72Z"/>
			<path style={style2} d="M27.67,9.34a3.7,3.7,0,0,0-7.29-.88H19.19V6.71a1.14,1.14,0,0,0-1.14-1.14H11.8a1.14,1.14,0,0,0-1.14,1.14V8.46H6.72a2.37,2.37,0,0,0-2.37,2.37v9.62a2.38,2.38,0,0,0,2.37,2.37H23.13a2.37,2.37,0,0,0,2.36-2.37V12.7A3.69,3.69,0,0,0,27.67,9.34ZM11.43,6.71a.37.37,0,0,1,.37-.37h6.25a.37.37,0,0,1,.36.37V8.46h-7ZM24.72,20.45a1.6,1.6,0,0,1-1.59,1.6H6.72a1.6,1.6,0,0,1-1.6-1.6V17.18H7.83v.28a.67.67,0,0,0,.64.7h1.78a.67.67,0,0,0,.64-.7v-.28h8v.28a.67.67,0,0,0,.64.7h1.78a.67.67,0,0,0,.65-.7v-.28h2.79v3.27Zm-16-3.14v-1H10v1Zm11,0v-1h1.37v1Zm5-.9h-2.8v-.29a.67.67,0,0,0-.64-.7H19.5a.67.67,0,0,0-.64.7v.29h-8v-.29a.67.67,0,0,0-.64-.7H8.47a.67.67,0,0,0-.64.7v.29H5.12V10.83a1.6,1.6,0,0,1,1.6-1.6H20.28a.41.41,0,0,0,0,.11A3.7,3.7,0,0,0,24,13a3.67,3.67,0,0,0,.75-.08Zm-.75-4A3.06,3.06,0,1,1,27,9.34,3.06,3.06,0,0,1,24,12.39Z"/>
		</svg>
	);
};

SvgProtectShortTimeWork.propTypes = {
	title: PropTypes.string
};

export default SvgProtectShortTimeWork;
