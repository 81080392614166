import React, { useEffect, useMemo } from 'react';
import C from 'constants/Constants';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { usePageUtils } from 'utils/page/page';
import { useApplicationApi } from 'api/application';
import { useDocumentsApi } from 'api/documents';

import { Text, Title } from 'components/Atoms/Text';
import { Row, Col } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import Divider from 'components/Atoms/Divider/Divider';
import Alert from 'components/Atoms/Alert/Alert';
import SvgIllustrationHandHoldingCheck from 'components/Atoms/SVG/Illustrations/SvgIllustrationHandHoldingCheck';
import SvgDownload from 'components/Atoms/SVG/Icons/SvgDownload';
import Button from 'components/Atoms/Button/Button';

import { useSelector } from 'react-redux';

import styles from './ApplicationApprovedGiro.module.scss';

const ApplicationApprovedGiro = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage, preventHistoryBackFor } = usePageUtils();
	const { continueValid } = useTrackingUtils();
	const { postApplication } = useApplicationApi();
	const { getContract } = useDocumentsApi();

	const currentFlow = getCurrentFlow();

	const storeDispoStatus = useSelector((state => state.dispo.status));

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
		if (storeDispoStatus === C.DISPO_STATUS.REJECTED) {
			postApplication(
				() => {},
				() => {},
				() => {},
				true
			);
		}
	}, []);

	const onSubmit = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
	};

	const pageTitle = useMemo(() => {
		let title;
		switch (storeDispoStatus) {
			case C.DISPO_STATUS.NA:
				title = m('afterPurchaseGiro.titleWithoutLimit', currentFlow);
				break;
			case C.DISPO_STATUS.APPROVED:
				title = m('afterPurchaseGiro.titleWithLimit', currentFlow);
				break;
			default:
				title = m('afterPurchaseGiro.titleWithoutLimit', currentFlow);
				break;
		}
		return title;
	}, [storeDispoStatus]);

	return (
		<>
			<Row>
				<Col xs={12} md={6}>
					<div className={styles.image} aria-hidden="true">
						<SvgIllustrationHandHoldingCheck />
					</div>
				</Col>
				<Col xs={12} md={6}>
					<Title mb>{pageTitle}</Title>
					<Divider noLine noMarginBottom />
					<Button
						buttonStyle="linkFlat"
						onClick={async () => {
							await getContract();
						}}
						prependIcon={<SvgDownload />}
						tabindex={1}
					>
						{m('afterPurchaseGiro.downloadLink', currentFlow)}
					</Button>
					<Divider noLine noMarginBottom />
					<Text>
						{m('afterPurchaseGiro.subtitle', currentFlow)}
					</Text>
				</Col>
			</Row>
			<Divider />
			<Text size="l">
				{m('afterPurchaseGiro.phrase', currentFlow)}
			</Text>
			{storeDispoStatus === C.DISPO_STATUS.REJECTED && (
				<Alert
					headline="Hinweis"
					messages={[{ code: FormErrorCode.OTHER, message: m('afterPurchaseGiro.error', currentFlow) }]}
					type="info"
				/>
			)}
			<Form
				submitText={m('afterPurchaseGiro.submitButton', currentFlow)}
				onSubmit={onSubmit}
				submitTabindex={2}
			/>
		</>
	);
};
export default ApplicationApprovedGiro;
