import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import { v4 as uuid } from 'uuid';

import styles from './TooltipPopup.module.scss';

const TooltipPopup = (props) => {
	const id = uuid();

	return (
		<span className={styles.tooltip}>
			<span
				className={styles.tooltipTrigger}
				data-tooltip-id={props.id ?? id}
			>
				{props.children}
			</span>
			<Tooltip
				id={props.id ?? id}
				effect="solid"
				place={props.position ? props.position : 'top'}
				isOpen={props.isOpen}
				events={['click']}
				className="custom-tooltip"
				classNameArrow="custom-tooltip-arrow"
			>
				{props.content}
			</Tooltip>
		</span>
	);
};

TooltipPopup.propTypes = {
	id: PropTypes.string,
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	position: PropTypes.string,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	children: PropTypes.any
};

export default TooltipPopup;
