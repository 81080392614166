import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgClose = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M18.35 5.65a.48.48 0 0 0-.7 0L12 11.29 6.36 5.65a.5.5 0 0 0-.71 0 .48.48 0 0 0 0 .7L11.29 12l-5.64 5.64a.5.5 0 0 0 0 .71.48.48 0 0 0 .7 0L12 12.71l5.64 5.64a.51.51 0 0 0 .36.15.5.5 0 0 0 .35-.85L12.71 12l5.64-5.64a.5.5 0 0 0 0-.71z" />
		</svg>
	);
};

SvgClose.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgClose;
