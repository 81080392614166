export const CREDIT_AMOUNT_MIN_VALUE = 1000;
export const CREDIT_AMOUNT_MAX_VALUE = 75000;
export const CREDIT_AMOUNT_DEFAULT = 10000;
export const CREDIT_RUNTIME_STEPS = [12, 18, 24, 36, 48, 60, 72, 84, 96];
export const CREDIT_RUNTIME_ITEMS = [
	{ label: '12 Monate', value: '12' },
	{ label: '18 Monate', value: '18' },
	{ label: '24 Monate', value: '24' },
	{ label: '36 Monate', value: '36' },
	{ label: '48 Monate', value: '48' },
	{ label: '60 Monate', value: '60' },
	{ label: '72 Monate', value: '72' },
	{ label: '84 Monate', value: '84' },
	{ label: '96 Monate', value: '96' }
];
export const CREDIT_RUNTIME_DEFAULT = 84;

export const PROTECT_MAX_AGE = 65;
