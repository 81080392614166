import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { useKeycodeUtils } from 'utils/keycodes/keycodes';
import { useRefUtils } from 'utils/refs/refs';

import HelperText from 'components/Atoms/Form/HelperText/HelperText';
import SvgCheck from 'components/Atoms/SVG/Icons/SvgCheck';

import textInputStyles from '../TextInput/TextInput.module.scss';
import styles from './CheckboxInput.module.scss';

export const CheckboxInput = (props) => {
	const { getReturn } = useKeycodeUtils();
	const { focusRef } = useRefUtils();

	const [id] = useState(uuid());

	const inputRef = useRef(null);

	const onChange = (event) => {
		props.setValue(event.currentTarget.checked);
	};

	const toggle = () => {
		if (!props.disabled) {
			focusRef(inputRef);
			props.setValue(!props.value);
		}
	};

	const handleKeyDown = (event) => {
		if (getReturn(event)) {
			event.preventDefault();
			toggle();
		}
	};

	return (
		<div className={textInputStyles.wrapper}>
			<div className={styles.inputWrapper}>
				<label
					htmlFor={id}
					className={classNames(
						textInputStyles.label,
						styles.label,
						'text--size--l',
						props.disabled ? styles.labelDisabled : null
					)}
				>
					{props.label}{props.required ? '*' : ''}
				</label>
				<input
					ref={inputRef}
					id={id}
					type="checkbox"
					className={classNames(
						styles.checkbox,
						props.hasError ? styles.error : null,
						props.hasError ? 'input--error' : null
					)}
					checked={!!props.value}
					onChange={onChange}
					onKeyDown={handleKeyDown}
					disabled={props.disabled}
					required={props.required}
					onInvalid={(event) => event.preventDefault()}
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
					aria-describedby={props.hasError ? `error-${id}` : null}
				/>
				<div
					className={classNames(styles.check, !!props.value ? styles.checked : null)}
					onClick={toggle}
				>
					<SvgCheck />
				</div>
			</div>
			<HelperText
				id={`error-${id}`}
				type={props.hasError ? 'error' : 'light'}
				message={props.message}
				hasIcon
			/>
		</div>
	);
};

CheckboxInput.propTypes = {
	value: PropTypes.bool,
	setValue: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	testId: PropTypes.string
};

export default CheckboxInput;
