import React, { useRef, useState } from 'react';
import C from 'constants/Constants';
import { C as Autocomplete } from 'constants/enums/Autocomplete';
import { C as FormErrorCode } from 'constants/enums/FormErrorCode';
import {
	CHAR_NUMBER_SPACE_DASH_DOT_INPUT,
	CHAR_SPACE_DASH_DOT_INPUT,
	NUMBER_INPUT
} from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';

import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import { TextInput } from 'components/Atoms/Form';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setPreviousStreet, setPreviousNumber, setPreviousPostcode, setPreviousCity } from 'store/Address/actions';

const PreviousAddress = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, addErrorMessage } = useValidationUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storePreviousStreet = useSelector((state => state.address.previousStreet));
	const storePreviousStreetNumber = useSelector((state => state.address.previousNumber));
	const storePreviousPostcode = useSelector((state => state.address.previousPostcode));
	const storePreviousCity = useSelector((state => state.address.previousCity));

	const [previousStreetError, setPreviousStreetError] = useState(null);
	const [previousStreetNumberError, setPreviousStreetNumberError] = useState(null);
	const [previousPostcodeError, setPreviousPostcodeError] = useState(null);
	const [previousCityError, setPreviousCityError] = useState(null);

	const previousCityRef = useRef(null);

	const clearErrors = () => {
		setPreviousStreetError(null);
		setPreviousStreetNumberError(null);
		setPreviousPostcodeError(null);
		setPreviousCityError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isValidPostcode = true;
		let isValidCity = true;
		let isValidStreet = true;

		if (isEmpty(storePreviousPostcode)) {
			setPreviousPostcodeError(m('validation.error.requiredNamed', 'fields', { name: m('postcode.label', 'fields') }));
			hasEmptyFields = true;
		} else if (storePreviousPostcode.length !== 5) {
			setPreviousPostcodeError(m('validation.error.postcode.length', 'fields'));
			isValidPostcode = false;
		}
		if (isEmpty(storePreviousCity)) {
			setPreviousCityError(m('validation.error.requiredNamed', 'fields', { name: m('city.label', 'fields') }));
			hasEmptyFields = true;
		} else if (storePreviousCity.length < 2) {
			setPreviousCityError(m('validation.error.city.length', 'fields'));
			isValidCity = false;
		}
		if (isEmpty(storePreviousStreet)) {
			setPreviousStreetError(m('validation.error.requiredNamed', 'fields', { name: m('street.label', 'fields') }));
			hasEmptyFields = true;
		} else if (storePreviousStreet.length < 3) {
			setPreviousStreetError(m('validation.error.street.length', 'fields'));
			isValidStreet = false;
		}
		if (isEmpty(storePreviousStreetNumber)) {
			setPreviousStreetNumberError(m('validation.error.requiredNamed', 'fields', { name: m('houseNumber.short', 'fields') }));
			hasEmptyFields = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		if (!hasEmptyFields && (!isValidPostcode || !isValidCity || !isValidStreet)) {
			await addErrorMessage(
				FormErrorCode.INVALID_FIELDS,
				m('validation.error.hasInvalidFields', 'fields')
			);
		}

		const isValid = !hasEmptyFields && isValidPostcode && isValidCity && isValidStreet;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		if (currentFlow === C.FRONTEND.VISACARD) {
			goToPage(currentFlow + C.ROUTES.BANK_ACCOUNT);
		} else {
			goToPage(currentFlow + C.ROUTES.MARITAL_STATUS);
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		dispatch(setPreviousStreet('Madrider Straße'));
		dispatch(setPreviousNumber('2'));
		dispatch(setPreviousPostcode('41069'));
		dispatch(setPreviousCity('Mönchengladbach'));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.previousAddress.title', 'global')}</Title>
			<Text size="l" mb>{m('pages.previousAddress.subtitle', 'global')}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={5}
				onPrevButton={() => { goToPage(currentFlow + C.ROUTES.ADDRESS); }}
			>
				<Row>
					<Col xs={8}>
						<TextInput
							value={storePreviousStreet}
							setValue={(value) => { dispatch(setPreviousStreet(value)); }}
							label={m('street.label', 'fields')}
							hasError={!!previousStreetError}
							message={previousStreetError}
							maxLength={50}
							regex={CHAR_SPACE_DASH_DOT_INPUT}
							autoComplete={Autocomplete.ADDRESS_LINE_1}
							tabindex={1}
							testId="street"
							required
						/>
					</Col>
					<Col xs={4}>
						<TextInput
							value={storePreviousStreetNumber}
							setValue={(value) => { dispatch(setPreviousNumber(value)); }}
							label={m('houseNumber.short', 'fields')}
							hasError={!!previousStreetNumberError}
							message={previousStreetNumberError}
							maxLength={8}
							regex={CHAR_NUMBER_SPACE_DASH_DOT_INPUT}
							autoComplete={Autocomplete.ADDRESS_LINE_2}
							tabindex={2}
							testId="house-number"
							required
						/>
					</Col>
					<Col xs={4}>
						<TextInput
							value={storePreviousPostcode}
							setValue={(value) => { dispatch(setPreviousPostcode(value)); }}
							label={m('postcode.label', 'fields')}
							hasError={!!previousPostcodeError}
							message={previousPostcodeError}
							maxLength={5}
							regex={NUMBER_INPUT}
							autoComplete={Autocomplete.POSTAL_CODE}
							nextRefOnMaxLength={previousCityRef}
							tabindex={3}
							testId="postal-code"
							required
						/>
					</Col>
					<Col xs={8}>
						<TextInput
							ref={previousCityRef}
							value={storePreviousCity}
							setValue={(value) => { dispatch(setPreviousCity(value)); }}
							label={m('city.label', 'fields')}
							hasError={!!previousCityError}
							message={previousCityError}
							maxLength={50}
							regex={CHAR_SPACE_DASH_DOT_INPUT}
							autoComplete={Autocomplete.ADDRESS_LEVEL_2}
							tabindex={4}
							testId="city"
							required
						/>
					</Col>
				</Row>
			</Form>
		</>
	);
};
export default PreviousAddress;
