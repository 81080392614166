import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgVisibilityOff = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M19.809 2.19c.187-.22.527-.25.769-.08a.462.462 0 0 1 .142.623l-.054.076-3.724 4.29v.01L15.41 8.875l-.972 1.124-5.123 5.905-.5.585-4.218 4.86-.406.46a.58.58 0 0 1-.769.08.462.462 0 0 1-.142-.623l.054-.077 3.724-4.3c-2.153-1-3.954-2.62-5.009-4.68a.47.47 0 0 1 0-.42C3.851 8.27 7.75 6 12 6c1.21 0 2.386.206 3.497.553l.413.137 3.899-4.5zm-1.89 5.4c1.725 1.01 3.142 2.45 4.032 4.2a.47.47 0 0 1 0 .42c-1.802 3.51-5.7 5.78-9.951 5.78-.819 0-1.622-.096-2.402-.262l-.388-.089.747-.86c.67.13 1.351.21 2.043.21 3.734 0 7.183-1.95 8.841-4.99-.769-1.41-1.92-2.583-3.302-3.44l-.3-.18.68-.79zM12 7c-3.734 0-7.183 1.95-8.841 5 .944 1.718 2.456 3.08 4.268 3.942l.323.147 1.12-1.29c-.78-.72-1.263-1.71-1.263-2.8 0-2.21 1.966-4 4.393-4 .742 0 1.423.174 2.036.472l.226.118.934-1.08A10.646 10.646 0 0 0 12 7zm3.8 3.04c.363.58.593 1.24.593 1.96 0 2.21-1.966 4-4.393 4a4.26 4.26 0 0 1-.982-.12l-.237-.06.758-.86c.153.02.307.04.461.04 1.812 0 3.295-1.35 3.295-3 0-.313-.056-.612-.158-.893l-.084-.208.747-.86zM12 9c-1.812 0-3.295 1.35-3.295 3 0 .7.272 1.341.718 1.85l.139.15 4.009-4.62C13.098 9.15 12.57 9 12 9z" />
		</svg>
	);
};

SvgVisibilityOff.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgVisibilityOff;
