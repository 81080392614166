import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link, Text } from 'components/Atoms/Text';
import SvgChevronDown from 'components/Atoms/SVG/Icons/SvgChevronDown';

import styles from './ToggleLink.module.scss';

const ToggleLink = (props) => {
	return (
		<div className={classNames([styles.wrapper, props.active ? styles.active : null])}>
			<Text size={props.size}>
				<Link
					onClick={(event) => {
						event.stopPropagation();
						props.setActive(!props.active);
					}}
					tabindex={props.tabindex}
					ariaLabel={props.ariaLabel ?? null}
					ariaExpanded={props.active}
					ariaControls={props.ariaControls ?? null}
				>
					{props.children}
					<SvgChevronDown />
				</Link>
			</Text>
		</div>
	);
};

ToggleLink.propTypes = {
	active: PropTypes.bool,
	setActive: PropTypes.func,
	size: PropTypes.string,
	tabindex: PropTypes.number,
	ariaLabel: PropTypes.string,
	ariaControls: PropTypes.string.isRequired,
	children: PropTypes.any
};

export default ToggleLink;
