import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import { Text } from 'components/Atoms/Text';
import SvgAlert from 'components/Atoms/SVG/Icons/SvgAlert';
import SvgCheckCircle from 'components/Atoms/SVG/Icons/SvgCheckCircle';
import SvgWarning from 'components/Atoms/SVG/Icons/SvgWarning';

import styles from './Alert.module.scss';
import titleStyles from '../Text/Title/Title.module.scss';


const Alert = (props) => {
	const [id] = useState(uuid());

	const type = useMemo(() => {
		switch (props.type) {
			case 'warning':
				return styles.warning;
			case 'success':
				return styles.success;
			case 'info':
				return styles.info;
			default:
				return styles.error;
		}
	}, [props.type]);

	const icon = useMemo(() => {
		switch (props.type) {
			case 'warning':
			case 'info':
				return <SvgWarning />;
			case 'success':
				return <SvgCheckCircle />;
			default:
				return <SvgAlert />;
		}
	}, [props.type]);

	const size = useMemo(() => {
		switch (props.size) {
			case 's':
				return styles.s;
			default:
				return styles.m;
		}
	}, [props.size]);

	return (
		<>
			{props.messages && props.messages.length > 0 && (
				<div id={props.id} className={styles.wrapper}>
					<div className={classNames(styles.alert, type, size)}>
						<div className={styles.icon}>{icon}</div>
						<div className={styles.content}>
							{props.headline && <p className={classNames(titleStyles.title, titleStyles.s)}>{props.headline}</p>}
							{props.messages.map((message, index) => (
								<Text key={'alert-message-' + id + '-' + index} size="m">
									<span
										dangerouslySetInnerHTML={{
											__html: message.message + ''
										}}
									/>
								</Text>
							))}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

Alert.propTypes = {
	id: PropTypes.string,
	headline: PropTypes.string,
	messages: PropTypes.array,
	type: PropTypes.string,
	size: PropTypes.string
};

export default Alert;
