import React from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title } from 'components/Atoms/Text';
import Box from 'components/Molecules/Box/Box';
import Form from 'components/Molecules/Form/Form';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';

import { useSelector } from 'react-redux';

const Dispo = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	const storeDispoAmount = useSelector((state => state.dispo.amount));

	const onSubmit = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.FINANCIAL_STATUS);
	};

	return (
		<Form
			onSubmit={onSubmit}
			submitTabindex={2}
			onPrevButton={ () => { goToPage(currentFlow + C.ROUTES.TIN); }}
		>
			<Row>
				<Col grow>
					<Title mb>{m('pages.dispo.title', 'global')}</Title>
				</Col>
				<Col noGrow>
					<div className="mt--5">
						<TooltipIcon
							text={
								<>
									<strong>{m('pages.dispo.tooltipTitle', 'global')}</strong>
									<br />
									{m('pages.dispo.tooltipText', 'global')}
								</>
							}
							tabindex={1}
						/>
					</div>
				</Col>
			</Row>
			<Text size="l" mb>{m('pages.dispo.subtitle', 'global', null, true)}</Text>
			<Box size="s" boxStyle="secondary" mb>
				<Text size="xl">{storeDispoAmount} €</Text>
			</Box>
			<Text size="l" mb>
				{m('pages.dispo.infoText', 'global', null, true)}
			</Text>
			<Text size="s">
				{m('pages.dispo.footNote', 'global')}
			</Text>
		</Form>
	);
};
export default Dispo;
