import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Link = (props) => {
	const color = useMemo(() => {
		switch (props.color) {
			case 'light':
				return 'light';
			case 'secondary':
				return 'secondary';
			default:
				return 'primary';
		}
	}, [props.color]);

	const classes = useMemo(() => {
		return classNames(
			'link',
			color,
			props.disabled ? 'link-disabled' : null,
			props.noWrap ? 'no-wrap' : null
		);
	}, [color, props.disabled, props.noWrap]);

	const onClick = (event) => {
		event.stopPropagation();
		if (props.onClick) {
			props.onClick(event);
		}
	};

	return (
		<>
			{props.onClick ? (
				<button
					className={classes}
					onClick={onClick}
					tabIndex={props.tabindex}
					aria-label={props.ariaLabel ?? null}
					aria-expanded={props.ariaExpanded ?? null}
					aria-checked={props.ariaChecked ?? null}
					aria-controls={props.ariaControls ?? null}
					role={props.role ?? null}
				>
					{props.children}
				</button>
			) : (
				<a
					href={props.href}
					className={classes}
					target={props.target}
					rel="noopener noreferrer"
					onClick={(event) => {
						event.stopPropagation();
					}}
					download={props.download ?? null}
					tabIndex={props.tabindex}
					aria-label={props.ariaLabel ?? null}
					aria-expanded={props.ariaExpanded ?? null}
					aria-checked={props.ariaChecked ?? null}
					aria-controls={props.ariaControls ?? null}
					role={props.role ?? null}
				>
					{props.children}
				</a>
			)}
		</>
	);
};

Link.propTypes = {
	href: PropTypes.string,
	target: PropTypes.string,
	color: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	noWrap: PropTypes.bool,
	underline: PropTypes.bool,
	noHover: PropTypes.bool,
	download: PropTypes.bool,
	tabindex: PropTypes.number,
	role: PropTypes.string,
	ariaLabel: PropTypes.string,
	ariaExpanded: PropTypes.bool,
	ariaChecked: PropTypes.bool,
	ariaControls: PropTypes.string,
	children: PropTypes.any
};

export default Link;
