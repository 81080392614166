import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import HelperText from '../HelperText/HelperText';
import Ripple from 'components/Atoms/Ripple/Ripple';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import SvgChevronDown from 'components/Atoms/SVG/Icons/SvgChevronDown';
import CSSIcon from 'components/Atoms/Icon/CSS/CSSIcon';

import textInputStyles from '../TextInput/TextInput.module.scss';
import styles from './DropdownInput.module.scss';

export const DropdownInput = (props) => {
	const [id] = useState(uuid());

	const inputRef = useRef(null);

	const onChange = (event) => {
		props.setValue(event.target.value);
	};

	const getIconByValue = (value) => {
		const item = props.items.find((item) => item.value === value);
		if (item && item.icon) {
			return (
				<div className={styles.icon}>
					<CSSIcon name={item.icon} />
				</div>
			);
		} else {
			return <></>;
		}
	};

	const placeholder = useMemo(
		() => props.placeholder ?? 'Bitte wählen',
	[props.placeholder]
	);

	return (
		<div className={textInputStyles.wrapper}>
			<div className={textInputStyles.labelWrapper}>
				<label htmlFor={id} className={textInputStyles.label}>
					{props.label}{props.required ? '*' : ''}
				</label>
				{props.tooltip && (
					<span className={textInputStyles.tooltip}>
						<TooltipIcon
							text={props.tooltip}
							tabindex={props.tooltipTabindex}
						/>
					</span>
				)}
			</div>
			<div className={textInputStyles.inputWrapper}>
				<select
					ref={inputRef}
					id={id}
					className={classNames(
						textInputStyles.input,
						styles.input,
						props.useIcons && props.value ? styles.inputUseIcons : null,
						props.hasError ? textInputStyles.error : null,
						props.hasError ? 'input--error' : null
					)}
					value={props.value ?? ''}
					onChange={onChange}
					disabled={props.disabled}
					required={props.required}
					onInvalid={(event) => event.preventDefault()}
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
					aria-describedby={props.hasError ? `error-${id}` : null}
				>
					<option disabled value="">
						{placeholder}
					</option>
					{props.items && props.items.length > 0 && (
						<>
							{props.items.map((item, index) => (
								<option key={id + '-' + index} value={item.value}>
									{item.label}
								</option>
							))}
						</>
					)}
				</select>
				{props.useIcons && props.value ? getIconByValue(props.value) : null}
				<div className={textInputStyles.ripple}>
					<Ripple rippleRef={inputRef} color="#cccccc" />
				</div>
				<div className={styles.arrow}>
					<SvgChevronDown />
				</div>
			</div>
			<HelperText
				id={`error-${id}`}
				type={props.hasError ? 'error' : 'light'}
				message={props.message}
				hasIcon
			/>
		</div>
	);
};

DropdownInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	placeholder: PropTypes.string,
	items: PropTypes.array,
	useIcons: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltipTabindex: PropTypes.number,
	tabindex: PropTypes.number,
	testId: PropTypes.string
};

export default DropdownInput;
