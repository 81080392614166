import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { m } from 'utils/messages/messages';

import { Link, Text } from 'components/Atoms/Text';
import SvgShare from 'components/Atoms/SVG/Icons/SvgShare';

import titleStyles from 'components/Atoms/Text/Title/Title.module.scss';

const AllowAdvertisingModalContent = (props) => {
	return (
		<>
			<p className={classNames(titleStyles.title, titleStyles.s, titleStyles.mb)}>{m('allowAdvertising.popup.headline', 'fields')}</p>
			<Text mb>
				{m('allowAdvertising.popup.content1', 'fields')}{' '}
				<Link href="https://www.santander.de/werbehinweise" target="_blank" tabindex={props.startTabindex}>
					{m('allowAdvertising.popup.linkText', 'fields')}
					<SvgShare />
				</Link>
				{m('allowAdvertising.popup.content2', 'fields', null, true)}
			</Text>
			<Text>
				{m('allowAdvertising.popup.content3', 'fields', null, true)}{' '}
				<Link href="https://www.santander.de/werbehinweise" target="_blank" tabindex={props.startTabindex ? props.startTabindex + 1 : null}>
					{m('allowAdvertising.popup.linkText', 'fields')}
					<SvgShare />
				</Link>
				{m('allowAdvertising.popup.content4', 'fields')}
			</Text>
		</>
	);
};

AllowAdvertisingModalContent.propTypes = {
	startTabindex: PropTypes.number
};

export default AllowAdvertisingModalContent;
