import React, { useEffect, useState } from 'react';
import C from 'constants/Constants';
import { NUMBER_INPUT } from 'constants/Regex';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';

import { Col, Row } from 'components/Atoms/Grid';
import { Text, Title, List } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { TextInput } from 'components/Atoms/Form';
import Box from 'components/Molecules/Box/Box';
import Divider from 'components/Atoms/Divider/Divider';
import SvgEuro from 'components/Atoms/SVG/Icons/SvgEuro';

import { useDispatch, useSelector } from 'react-redux';
import { updateAmount } from 'store/App/actions';

import styles from './CreditAmount.module.scss';

const CreditAmount = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeAmount = useSelector((state => state.app.amount));

	const [amountError, setAmountError] = useState(null);

	useEffect(() => {
		dispatch(updateAmount(null));
	}, []);

	const clearErrors = () => {
		setAmountError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(parseInt(storeAmount))) {
			setAmountError(m('validation.error.requiredNamed', 'fields', { name: m('wishAmountShortCredit.label', 'fields') }));
			hasEmptyFields = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};


	const onValid = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.SHORT_APPLICATION);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	return (
		<div className={styles.wrapper}>
			<Row justifyContent="center">
				<Col xs={12} md={6} xl={4}>
					<Box borderRadius shadow>
						<Title size="l" mb>
							<strong>
								{m('amount.headline', currentFlow, null, true)}
							</strong>
						</Title>

						<List size="m" listStyle="checks">
							<li>{m('amount.bullet1', currentFlow)}</li>
							<li>{m('amount.bullet2', currentFlow)}</li>
							<li>{m('amount.bullet3', currentFlow)}</li>
						</List>

						<Divider noLine noMarginBottom />

						<Form
							submitText={m('amount.submit', currentFlow)}
							onSubmit={onSubmit}
							submitOnly
							clearErrors={clearErrors}
							submitTabindex={2}
						>
							<TextInput
								value={storeAmount || storeAmount === 0 ? storeAmount + '' : null}
								setValue={(value) => { dispatch(updateAmount(value)); }}
								label={m('amount.label', currentFlow)}
								suffix={<SvgEuro />}
								hasError={!!amountError}
								message={amountError}
								regex={NUMBER_INPUT}
								tabindex={1}
								testId="amount"
								required
							/>
						</Form>
						<div className="mt--15 text-align-center">
							<Text>{m('amount.hint', currentFlow)}</Text>
						</div>
					</Box>
				</Col>
			</Row>
		</div>
	);
};
export default CreditAmount;
