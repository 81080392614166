import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgCircularEconomy = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M11.027 3.003c4.774 0 8.683 3.698 9.002 8.38l1.034-1.787a.5.5 0 0 1 .682-.182l.077.053a.5.5 0 0 1 .108.631l-2.006 3.465a.5.5 0 0 1-.682.183l-.073-.05-.098-.098-.035-.044-2.3-3.278-.046-.079a.5.5 0 0 1 .168-.617l.08-.046a.5.5 0 0 1 .617.167l1.49 2.123c-.094-4.336-3.648-7.821-8.018-7.821-4.43 0-8.022 3.581-8.022 8 0 4.418 3.592 8 8.022 8a8.015 8.015 0 0 0 6.334-3.092l.062-.07a.5.5 0 0 1 .77.63l-.225.283a9.015 9.015 0 0 1-6.941 3.249c-4.984 0-9.024-4.03-9.024-9s4.04-9 9.024-9zm.335 9.532a.5.5 0 0 1 .09.992l-.09.008-3.688.001c.571 1.386 2.117 2.357 3.9 2.357 1.08 0 2.094-.356 2.857-.98a.5.5 0 1 1 .633.774c-.943.771-2.18 1.206-3.49 1.206-2.297 0-4.307-1.342-4.935-3.276a.503.503 0 0 1-.02-.081H5.8a.5.5 0 0 1-.09-.993l.09-.008h5.562zm.211-5.635c1.265 0 2.461.404 3.392 1.127a.5.5 0 0 1-.613.79c-.755-.586-1.734-.917-2.779-.917-1.715 0-3.212.899-3.83 2.202l-.085.193 3.704.001a.5.5 0 0 1 .492.41l.008.09a.5.5 0 0 1-.41.492l-.09.008H5.8a.5.5 0 0 1-.09-.992l.09-.008h.827a.5.5 0 0 1 .026-.163C7.296 8.22 9.294 6.9 11.573 6.9z" />
		</svg>
	);
};

SvgCircularEconomy.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgCircularEconomy;
