import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePhoneUtils } from 'utils/phone/phone';

import { Link } from 'components/Atoms/Text';

const PhoneLink = (props) => {
	const { getPhoneNumber } = usePhoneUtils();

	const [phoneNumber] = useState(getPhoneNumber());

	return (
		<Link href={'tel:' + phoneNumber?.link} tabindex={props.tabindex}>
			{phoneNumber?.text}
		</Link>
	);
};

PhoneLink.propTypes = {
	tabindex: PropTypes.number
};

export default PhoneLink;
