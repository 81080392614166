import React, { useEffect, useState } from 'react';
import C from 'constants/Constants';
import { C as Protect } from 'constants/enums/Protect';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useInsuranceUtils } from 'utils/insurance/insurance';
import { useNumberUtils } from '../../../utils/number/number';
import { useCreditUtils } from '../../../utils/credit/credit';

import { Title, Text, List } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { CheckboxInput, DropdownInput, RadioBoxInput } from 'components/Atoms/Form';
import RadioBoxItem from 'components/Atoms/Form/RadioBoxInput/RadioBoxItem';
import Overview from 'components/Pages/Insurance/Overview/Overview';
import SvgScales from 'components/Atoms/SVG/Icons/SvgScales';
import Divider from 'components/Atoms/Divider/Divider';
import ProtectInfoModal from './ProtectInfoModal';

import { useDispatch, useSelector } from 'react-redux';
import { setProtect, setProtectCoverage } from '../../../store/Insurance/actions';

const Insurance = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid, ecommerceAddToCart, ecommerceRemoveFromCart } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { showInsurancePage } = useInsuranceUtils();
	const { roundUp, formatMoneyValue } = useNumberUtils();
	const { getProtectMonthlyRateByProtect } = useCreditUtils();

	const [noProtect, setNoProtect] = useState(null);
	const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);

	const [protectCoverageError, setProtectCoverageError] = useState(null);
	const [protectError, setProtectError] = useState(null);
	const [noProtectError, setNoProtectError] = useState(null);

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeProtectCoverage = useSelector((state => state.insurance.protectCoverage));
	const storeIncome = useSelector((state => state.financialStatus.income));
	const storeProtect = useSelector((state => state.insurance.protect));

	const protectCoverageItems = [
		/*{ value: '100', label: '100 €' },*/
		{ value: '200', label: '200 €' },
		{ value: '300', label: '300 €' },
		{ value: '400', label: '400 €' },
		{ value: '500', label: '500 €' },
		{ value: '600', label: '600 €' },
		{ value: '700', label: '700 €' },
		{ value: '800', label: '800 €' },
		{ value: '900', label: '900 €' },
		{ value: '1000', label: '1.000 €' }
	];

	const getFormattedProtectMonthlyRate = (protect) => (formatMoneyValue(getProtectMonthlyRateByProtect(protect), true) ?? 0) + ' €/' + m('month.label', 'fields');

	const getProtectDescription = (check1, check2, check3, check4) => (
		<>
			<Text size="s"><strong>{m('pages.insurance.protect.body.table.spacer.monthlyPayment', 'global')}</strong></Text>
			<List size="s" listStyle="checks">
				{check1 && <li>{m('pages.insurance.protect.body.table.row1.title', 'global')}</li>}
				{check2 && <li>{m('pages.insurance.protect.body.table.row2.title', 'global')}</li>}
				{check3 && <li>{m('pages.insurance.protect.body.table.row3.title', 'global')}</li>}
			</List>
			<Text size="s"><strong>{m('pages.insurance.protect.body.table.spacer.oneTimePayment', 'global')}</strong></Text>
			<List size="s" listStyle="checks">
				{check4 && <li>{m('pages.insurance.protect.body.table.row4.title', 'global')}</li>}
			</List>
		</>
	);

	const protectItems = [
		{
			value: Protect.BASIC,
			content: (
				<RadioBoxItem
					title={m('pages.insurance.protect.body.table.basic.title', 'global')}
					description={getFormattedProtectMonthlyRate(Protect.BASIC)}
					tooltip={<ProtectInfoModal check3 check4 />}
					tooltipTabindex={3}
					tooltipModalTabindexClose={300}
					tooltipModalSetFocusToTabindexOnOpen={300}
					tooltipIsPopup
					onModalOpen={() => { setInfoModalIsOpen(true); }}
					onModalClose={() => { setInfoModalIsOpen(false); }}
					additionalContent={getProtectDescription(false, false, true, true)}
				/>
			)
		},
		{
			value: Protect.STANDARD,
			content: (
				<RadioBoxItem
					title={m('pages.insurance.protect.body.table.standard.title', 'global')}
					description={getFormattedProtectMonthlyRate(Protect.STANDARD)}
					tooltip={<ProtectInfoModal check2 check3 check4 />}
					tooltipTabindex={4}
					tooltipModalTabindexClose={301}
					tooltipModalSetFocusToTabindexOnOpen={301}
					tooltipIsPopup
					onModalOpen={() => { setInfoModalIsOpen(true); }}
					onModalClose={() => { setInfoModalIsOpen(false); }}
					additionalContent={getProtectDescription(false, true, true, true)}
				/>
			)
		},
		{
			value: Protect.PREMIUM,
			banner: m('pages.insurance.protect.body.table.premium.higlightLabel', 'global'),
			content: (
				<RadioBoxItem
					title={m('pages.insurance.protect.body.table.premium.title', 'global')}
					description={getFormattedProtectMonthlyRate(Protect.PREMIUM)}
					tooltip={<ProtectInfoModal check1 check2 check3 check4 />}
					tooltipTabindex={5}
					tooltipModalTabindexClose={302}
					tooltipModalSetFocusToTabindexOnOpen={302}
					tooltipIsPopup
					onModalOpen={() => { setInfoModalIsOpen(true); }}
					onModalClose={() => { setInfoModalIsOpen(false); }}
					additionalContent={getProtectDescription(true, true, true, true)}
				/>
			)
		}
	];

	useEffect(() => {
		if (!showInsurancePage()) {
			goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
		} else if(storeProtectCoverage === null) {
			if (storeIncome) {
				dispatch(setProtectCoverage(roundUp((parseInt(storeIncome) * 0.4), 100, 1000)));
			} else {
				dispatch(setProtectCoverage(parseInt(protectCoverageItems[0].value)));
			}
		}
	}, []);

	const clearErrors = () => {
		setProtectCoverageError(null);
		setProtectError(null);
		setNoProtectError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (noProtect === null && storeProtect === null) {
			setProtectError(m('validation.error.requiredNamed', 'fields', { name: m('pages.insurance.protect.body.selectTitleShort', 'global') }));
			setNoProtectError(m('validation.error.requiredNamed', 'fields', { name: m('pages.insurance.protect.body.table.noProtectShort', 'global') }));
			hasEmptyFields = true;
		} else if (!noProtect) {
			if (isEmpty(storeProtectCoverage)) {
				setProtectCoverageError(m('validation.error.requiredNamed', 'fields', { name: m('pages.insurance.protect.body.coverage.label', 'global') }));
				hasEmptyFields = true;
			}
			if (isEmpty(storeProtect)) {
				setProtectError(m('validation.error.requiredNamed', 'fields', { name: m('pages.insurance.protect.body.selectTitleShort', 'global') }));
				hasEmptyFields = true;
			}
		}

		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();

		const protectFormatted = storeProtect ? storeProtect.toLowerCase() : null;
		if (storeProtect && (protectFormatted === Protect.STANDARD || protectFormatted === Protect.PREMIUM || protectFormatted === Protect.BASIC)) {
			ecommerceAddToCart(true, 'protect');
		} else {
			ecommerceRemoveFromCart('protect');
		}

		goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const onPrevButton = () => {
		goToPage(currentFlow + C.ROUTES.EXTERNAL_CREDIT);
	};

	return (
		<>
			<Title mb>{m('pages.insurance.title', 'global')}</Title>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={7}
				onPrevButton={onPrevButton}
				hideSubmitDividerMobile
			>
				<Overview />
				<Text mb>
					<SvgScales color="#ec0000" />
					<strong>{m('pages.insurance.protect.title', 'global')}<sup>1)</sup></strong>
				</Text>
				<Text mb>{m('pages.insurance.protect.subtitle', 'global')}</Text>
				<Text mb>{m('pages.insurance.protect.body.description', 'global')}</Text>
				<DropdownInput
					value={storeProtectCoverage ? `${storeProtectCoverage}` : ''}
					setValue={(value) => {
						dispatch(setProtectCoverage(value && value !== '' ? +value : null));
					}}
					items={protectCoverageItems}
					label={m('pages.insurance.protect.body.coverage.label', 'global')}
					hasError={!!protectCoverageError}
					message={protectCoverageError}
					tabindex={1}
					testId="protect-coverage"
					required
				/>

				<RadioBoxInput
					value={storeProtect}
					setValue={(value) => {
						if (value) {
							setNoProtect(false);
						}
						dispatch(setProtect(value));
					}}
					items={protectItems}
					label={m('pages.insurance.protect.body.selectTitle', 'global')}
					hasError={!!protectError}
					message={protectError}
					tabindex={2}
					testId="protect"
					infoModalIsOpen={infoModalIsOpen}
					required
				/>

				<CheckboxInput
					value={noProtect}
					setValue={(value) => {
						if (value === true) {
							dispatch(setProtect(null));
						}
						setNoProtect(value);
					}}
					label={m('pages.insurance.protect.body.table.noProtect', 'global')}
					hasError={!!noProtectError}
					message={noProtectError}
					tabindex={6}
					testId="no-protect"
				/>
			</Form>
			<Divider />
			<Text mb size="s">{m('pages.insurance.protect.hint', 'global')}</Text>
		</>
	);
};
export default Insurance;
