import React from 'react';
import PropTypes from 'prop-types';

import styles from './ProtectInfoModal.module.scss';
import SvgProtectRespiteCare from '../../Atoms/SVG/Icons/Protect/SvgProtectRespiteCare';
import { m } from '../../../utils/messages/messages';
import SvgProtectParentalLeave from '../../Atoms/SVG/Icons/Protect/SvgProtectParentalLeave';
import SvgProtectShortTimeWork from '../../Atoms/SVG/Icons/Protect/SvgProtectShortTimeWork';
import SvgProtectUnemployment from '../../Atoms/SVG/Icons/Protect/SvgProtectUnemployment';
import SvgProtectIncapacityForWork from '../../Atoms/SVG/Icons/Protect/SvgProtectIncapacityForWork';

const ProtectInfoModal = (props) => {
	return (
		<div className={styles.popup}>
			{props.check1 && (
				<>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectRespiteCare />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row1.popup.headline1', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row1.popup.content1', 'global', null, true)}</div>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectParentalLeave />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row1.popup.headline2', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row1.popup.content2', 'global', null, true)}</div>
				</>
			)}
			{props.check2 && (
				<>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectShortTimeWork />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row2.popup.headline1', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row2.popup.content1', 'global', null, true)}</div>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectUnemployment />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row2.popup.headline2', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row2.popup.content2', 'global', null, true)}</div>
				</>
			)}
			{props.check3 && (
				<>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectIncapacityForWork />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row3.popup.headline1', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row3.popup.content1', 'global', null, true)}</div>
				</>
			)}
			{props.check4 && (
				<>
					<div className={styles.popupHeader}>
						<div className={styles.popupHeaderIcon}>
							<SvgProtectParentalLeave />
						</div>
						<p className={styles.popupHeadline}>{m('pages.insurance.protect.body.table.row4.popup.headline1', 'global')}</p>
					</div>
					<div className={styles.popupText}>{m('pages.insurance.protect.body.table.row4.popup.content1', 'global', null, true)}</div>
				</>
			)}
		</div>
	);
};

ProtectInfoModal.propTypes = {
	check1: PropTypes.bool,
	check2: PropTypes.bool,
	check3: PropTypes.bool,
	check4: PropTypes.bool
};

export default ProtectInfoModal;
