import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgShare = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M9.505 2.004a.5.5 0 0 1 .09.992l-.09.008H5.973a2.977 2.977 0 0 0-2.968 2.798L3 5.977v12.05c0 1.581 1.24 2.878 2.799 2.969l.174.005h12.054c1.58 0 2.877-1.24 2.968-2.799l.005-.174V14.5a.5.5 0 0 1 .992-.09l.008.09v3.527a3.978 3.978 0 0 1-3.775 3.968l-.198.005H5.973a3.978 3.978 0 0 1-3.968-3.775L2 18.028V5.977a3.978 3.978 0 0 1 3.775-3.969l.198-.004h3.532zm11.95 0a.493.493 0 0 1 .41.135.48.48 0 0 1 .138.321L22 2.548v4.955a.5.5 0 0 1-.992.09L21 7.503V3.71l-8.626 8.626a.5.5 0 0 1-.765-.637l.058-.07 8.626-8.626H16.5a.499.499 0 0 1-.09-.992l.09-.008h4.955z" />
		</svg>
	);
};

SvgShare.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgShare;
