import React from 'react';
import { m } from 'utils/messages/messages';

import { Text } from 'components/Atoms/Text';

const RequiredFieldsHint = () => {
	return (
		<Text size="s" mb>
				{m('requiredFields.hint', 'fields')}
		</Text>
	);
};
export default RequiredFieldsHint;
