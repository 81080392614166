import React from 'react';
import { m } from 'utils/messages/messages';

import { Title } from 'components/Atoms/Text';
import SantanderBranchHint from 'components/Atoms/Partials/SantanderBranchHint/SantanderBranchHint';
import { useUtils } from '../../../../utils/utils';

const NoUs = () => {
	const { getCurrentFlow } = useUtils();

	const currentFlow = getCurrentFlow();

	return (
		<>
			<Title mb>{m('noUs.title', currentFlow)}</Title>
			<SantanderBranchHint link={m('general.contactSubsidiaryLink', 'global')} />
		</>
	);
};
export default NoUs;
