import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from 'components/Atoms/Modal/Modal';
import SvgQuestionmarkCircle from 'components/Atoms/SVG/Icons/SvgQuestionmarkCircle';

import styles from './PopupIcon.module.scss';

const PopupIcon = (props) => {
	const [isOpenPopup, setIsOpenPopup] = useState(false);

	const openPopup = (event) => {
		event.stopPropagation();
		props.onModalOpen(event);
		setIsOpenPopup(true);
	};

	const closePopup = (event) => {
		event.stopPropagation();
		props.onModalClose(event);
		setIsOpenPopup(false);
	};

	return (
		<>
			<button
				onClick={openPopup}
				className={classNames(
					styles.icon,
					props.inTitleXl ? styles.inTitleXl : null
				)}
				tabIndex={props.tabindex}
			>
				<SvgQuestionmarkCircle />
			</button>
			<Modal
				isOpen={isOpenPopup}
				onClose={closePopup}
				tabindexClose={props.tabindexClose}
				setFocusToTabindexOnOpen={props.setFocusToTabindexOnOpen}
				setFocusToTabindexOnClose={props.tabindex}
			>
				{props.content}
			</Modal>
		</>
	);
};

PopupIcon.propTypes = {
	content: PropTypes.any,
	inTitleXl: PropTypes.bool,
	tabindex: PropTypes.number,
	tabindexClose: PropTypes.number,
	setFocusToTabindexOnOpen: PropTypes.number,
	onModalOpen: PropTypes.func,
	onModalClose: PropTypes.func
};

export default PopupIcon;
