import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgLoader = (props) => {
	const [titleId] = useState(uuid());
	const width = 10;
	const height = 10;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M5 10a.536.536 0 010-1.071A3.933 3.933 0 008.929 5 3.933 3.933 0 005 1.071 3.933 3.933 0 001.071 5 .536.536 0 010 5c0-2.757 2.243-5 5-5s5 2.243 5 5-2.243 5-5 5" />
		</svg>
	);
};

SvgLoader.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgLoader;
