import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgVisibilityOn = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M12 6c4.336 0 8.125 2.457 9.951 6.285a.5.5 0 0 1 0 .43C20.125 16.543 16.336 19 12 19s-8.125-2.457-9.951-6.285a.5.5 0 0 1 0-.43C3.875 8.457 7.664 6 12 6zm0 1c-3.864 0-7.243 2.14-8.943 5.5C4.757 15.86 8.137 18 12 18c3.864 0 7.243-2.14 8.943-5.5C19.243 9.14 15.863 7 12 7zm0 1.503a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6z" />
		</svg>
	);
};

SvgVisibilityOn.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgVisibilityOn;
