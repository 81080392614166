import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useIbanUtils } from 'utils/iban/iban';

import { Row, Col } from 'components/Atoms/Grid';
import { Text, Title, List, Link } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { CheckboxInput } from 'components/Atoms/Form';
import SvgShare from 'components/Atoms/SVG/Icons/SvgShare';
import SvgIllustrationHandHoldingCard from 'components/Atoms/SVG/Illustrations/SvgIllustrationHandHoldingCard';
import NextSteps from 'components/Atoms/Partials/NextSteps/NextSteps';
import PopupIcon from 'components/Atoms/PopupIcon/PopupIcon';
import DownloadContracts from 'components/Atoms/Partials/DownloadContracts/DownloadContracts';

import { useDispatch, useSelector } from 'react-redux';
import {
	setAcceptTermsAndConditions,
	setOwnResponsibility,
	setAcceptSchufa
} from 'store/Consent/actions';

import styles from './ApplicationApprovedCreditCard.module.scss';
import titleStyles from '../../../../Atoms/Text/Title/Title.module.scss';

const ApplicationApprovedCreditCard = () => {
	const { getCurrentFlow, isCreditCardSubType } = useUtils();
	const { goToPage, preventHistoryBackFor } = usePageUtils();
	const { ibanContainsSantanderBlz } = useIbanUtils();
	const { continueValid } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeReferenceIban = useSelector((state => state.bankAccount.referenceIban));

	const [isSantanderIban] = useState(storeReferenceIban ? ibanContainsSantanderBlz(storeReferenceIban) : false);
	const [bestGiroEnabled, setBestGiroEnabled] = useState(false);

	const steps = [
		m('bcoResponseGreen.nextSteps.step1', currentFlow),
		m('bcoResponseGreen.nextSteps.step2', currentFlow),
		m('bcoResponseGreen.nextSteps.step3', currentFlow)
	];

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
	}, []);

	const onSubmit = () => {
		continueValid();
		if (
			process.env.REACT_APP_ENABLE_BEST_CARD_GIRO_CROSS_SELLING === 'true'
			&& isCreditCardSubType(null, [C.FRONTEND.VISACARD])
			&& !isSantanderIban
			&& bestGiroEnabled
		) {
			dispatch(setAcceptTermsAndConditions(false));
			dispatch(setOwnResponsibility(false));
			dispatch(setAcceptSchufa(false));
			goToPage(currentFlow + C.ROUTES.GIRO_DATA);
		} else if (isCreditCardSubType(null, [C.FRONTEND.VISACARD])) {
			goToPage(currentFlow + C.ROUTES.UPLOAD_DOCUMENTS);
		} else {
			goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
		}
	};

	const submitText = useMemo(() => {
		if (
			process.env.REACT_APP_ENABLE_BEST_CARD_GIRO_CROSS_SELLING === 'true'
			&& isCreditCardSubType(null, [C.FRONTEND.VISACARD])
			&& !isSantanderIban
		) {
			if (bestGiroEnabled) {
				return m('bcoResponseGreen.bestGiro.submit.withBestGiro', currentFlow);
			} else {
				return m('bcoResponseGreen.bestGiro.submit.withoutBestGiro', currentFlow);
			}
		}
		return m('bcoResponseGreen.submit', currentFlow);
	}, [currentFlow, isSantanderIban, bestGiroEnabled]);

	const showCrosssell = useMemo(() =>
		process.env.REACT_APP_ENABLE_BEST_CARD_GIRO_CROSS_SELLING === 'true'
		&& isCreditCardSubType(null, [C.FRONTEND.VISACARD])
		&& !isSantanderIban,
	[isSantanderIban]);

	return (
		<>
			<Row>
				<Col xs={12} sm={4}>
					<div className={styles.image} aria-hidden="true">
						<SvgIllustrationHandHoldingCard />
					</div>
				</Col>
				<Col xs={12} sm={8}>
					<div className={styles.title}>
						<Title mb>
							{m('bcoResponseGreen.title', currentFlow, null, true)}
						</Title>
					</div>
				</Col>
			</Row>
			<NextSteps
				title={m('bcoResponseGreen.nextSteps.title', currentFlow)}
				steps={steps}
			/>
			<Form
				onSubmit={onSubmit}
				submitText={submitText}
				submitTabindex={showCrosssell ? 3 : 1}
				prevButtonExtension={currentFlow !== C.FRONTEND.VISACARD ? <DownloadContracts submitTabindex={showCrosssell ? 3 : 1} />: null}
			>
				{showCrosssell && (
					<>
						<div className={styles.box}>
							<div className={styles.banner}>
								{m('bcoResponseGreen.bestGiro.banner', currentFlow)}
							</div>
							<CheckboxInput
								value={bestGiroEnabled}
								setValue={setBestGiroEnabled}
								label={<strong>{m('bcoResponseGreen.bestGiro.label', currentFlow)}</strong>}
								tabindex={1}
								testId="best-giro-enabled"
							/>
							<div className={classNames(styles.list)}>
								<List listStyle="checks">
									<li>
										{m('bcoResponseGreen.bestGiro.list.item1', currentFlow)}
										<span className={styles.tooltipWrapper}>
											<span className={styles.tooltip}>
												<PopupIcon
													content={
														<>
															<p className={classNames(titleStyles.title, titleStyles.s, titleStyles.mb)}>
																{m('bcoResponseGreen.bestGiro.list.item1Tooltip.title', currentFlow)}
															</p>
															<List size="m">
																<li>{m('bcoResponseGreen.bestGiro.list.item1Tooltip.item1', currentFlow)}</li>
																<li>{m('bcoResponseGreen.bestGiro.list.item1Tooltip.item2', currentFlow)}</li>
																<li>{m('bcoResponseGreen.bestGiro.list.item1Tooltip.item3', currentFlow)}</li>
																<li>{m('bcoResponseGreen.bestGiro.list.item1Tooltip.item4', currentFlow)}</li>
																<li>{m('bcoResponseGreen.bestGiro.list.item1Tooltip.item5', currentFlow)}</li>
																<li>{m('bcoResponseGreen.bestGiro.list.item1Tooltip.item6', currentFlow)}</li>
															</List>
															<Text>
																<Link
																	href="https://www.santander.de/privatkunden/service-kontakt/service/echtzeit-ueberweisung/"
																	target="_blank"
																	tabindex={300}
																>
																	{m('bcoResponseGreen.bestGiro.list.item1Tooltip.linkText', currentFlow)}
																	<SvgShare />
																</Link>
															</Text>
														</>
													}
													tabindex={2}
													tabindexClose={301}
													setFocusToTabindexOnOpen={300}
													setFocusToTabindexOnClose={2}
												/>
											</span>
										</span>
									</li>
									<li>{m('bcoResponseGreen.bestGiro.list.item2', currentFlow)}</li>
								</List>
							</div>
						</div>
					</>
				)}
			</Form>
		</>
	);
};
export default ApplicationApprovedCreditCard;
