import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Text } from 'components/Atoms/Text';
import List from 'components/Atoms/Text/List/List';
import ToggleLink from 'components/Atoms/Partials/ToggleLink/ToggleLink';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import Divider from 'components/Atoms/Divider/Divider';
import PopupIcon from '../../PopupIcon/PopupIcon';

import styles from './RadioBoxItem.module.scss';
import titleStyles from '../../../Atoms/Text/Title/Title.module.scss';

const RadioBoxItem = (props) => {
	const [show, setShow] = useState(false);

	return (
		<>
			{props.title && (
				<div className={styles.titleWrapper}>
					<div className={styles.title}>
						<p className={classNames(titleStyles.title, titleStyles.s)}>{props.title}</p>
						{props.tooltip && (
							<div className={styles.tooltip}>
								{props.tooltipIsPopup ? (
									<PopupIcon
										content={props.tooltip}
										tabindex={props.tooltipTabindex}
										tabindexClose={props.tooltipModalTabindexClose}
										setFocusToTabindexOnOpen={props.tooltipModalSetFocusToTabindexOnOpen}
										onModalOpen={props.onModalOpen}
										onModalClose={props.onModalClose}
									/>
								) : (
									<TooltipIcon
										text={props.tooltip}
										tabindex={props.tooltipTabindex}
									/>
								)}
						</div>
					)}
				</div>
			{props.icon && <div className={styles.icon}>{props.icon}</div>}
				</div>
			)}
			<div className={styles.content}>
				{props.description && (
					<div
						className={classNames(
							styles.description,
							props.hasFixedDescriptionHeight ? styles.fixedHeight : null
						)}
					>
						<Text>{props.description}</Text>
					</div>
				)}
				{props.separatedDescription && (
					<>
						<Divider noMarginTop noMarginBottom />
						<Text>{props.separatedDescription}</Text>
					</>
				)}
				{props.list && <List listStyle='checks'>{props.list}</List>}
				{props.toggleButtonText && props.toggleContent && (
					<>
						<ToggleLink
							active={show}
							setActive={setShow}
							ariaLabel={props.ariaLabelActive && props.ariaLabelInactive ? (show ? props.ariaLabelActive : props.ariaLabelInactive) : null}
							ariaControls={props.ariaControls ?? null}
							tabindex={props.toggleButtonTabindex ?? null}
						>
							{props.toggleButtonText}
						</ToggleLink>
						{show && (
							<div
								id={props.ariaControls ?? null}
								role="dialog"
							>
								{props.toggleContent}
							</div>
						)}
					</>
				)}
				{props.additionalContent && (
					<div className={styles.additionalContent}>
						<Divider noMarginTop noMarginBottom />
						{props.additionalContent}
					</div>
				)}
				{props.footer && <div className={styles.footer}>{props.footer}</div>}
			</div>
		</>
	);
};

RadioBoxItem.propTypes = {
	title: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltipTabindex: PropTypes.number,
	tooltipIsPopup: PropTypes.bool,
	tooltipModalTabindexClose: PropTypes.number,
	tooltipModalSetFocusToTabindexOnOpen: PropTypes.number,
	onModalOpen: PropTypes.func,
	onModalClose: PropTypes.func,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	hasFixedDescriptionHeight: PropTypes.bool,
	separatedDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	list: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	toggleButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	toggleContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	additionalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	toggleButtonTabindex: PropTypes.number,
	ariaLabelActive: PropTypes.string,
	ariaLabelInactive: PropTypes.string,
	ariaControls: PropTypes.string
};

export default RadioBoxItem;
