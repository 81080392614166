import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgEmailResend = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="m21.99,9.73l-.02-.06-.02-.04-.04-.08h0v-.03s-2.9-3.61-2.9-3.61v-3.41c0-.28-.22-.5-.5-.5H5.5c-.28,0-.5.22-.5.5v3.41l-2.89,3.61v.02h-.02s-.02.05-.02.05c0,0-.04.07-.05.11,0,.02,0,.05-.01.07v8.33c-.01.81.24,1.57.67,2.19.02.06.05.11.09.15.03.03.06.05.09.07.71.89,1.8,1.48,3.03,1.48h12.22c1.2,0,2.34-.57,3.07-1.53l.04-.03.04-.07c.48-.66.74-1.45.74-2.26v-8.37Zm-2.99-2.22l1.77,2.21-1.77,1.19v-3.4Zm-13,3.99V3h12v8.5s.01.05.02.07l-3.44,2.31-1.25-1.11c-.76-.67-1.9-.67-2.65,0l-1.25,1.11-3.44-2.31s.01-.05.01-.07Zm-1-3.99v3.4l-1.77-1.19,1.77-2.21Zm-1.72,11.82c-.18-.38-.28-.8-.28-1.22v-7.34l5.65,3.79-5.37,4.77Zm14.82,1.67H5.89c-.76,0-1.5-.3-2.04-.84l7.49-6.64c.38-.34.95-.34,1.32,0l7.48,6.64c-.54.54-1.27.84-2.04.84Zm2.9-2.89c0,.44-.1.85-.28,1.22l-5.37-4.76,5.65-3.8v7.34Z"/>
			<path d="m12,11.16c1.83,0,3.31-1.49,3.31-3.32,0-1.5-1-2.78-2.38-3.19h.02c.2-.12.27-.38.14-.6l-.02-.03s0-.01-.01-.02c-.13-.15-.36-.19-.53-.09l-1.18.68c-.1.06-.17.15-.2.25-.03.11-.01.22.07.36,0,.01.05.06.06.07s.04.03.05.04l1.11.78s.05.03.07.04c.19.08.4.02.52-.14,0-.01.03-.05.04-.07.05-.1.05-.22.01-.32.82.41,1.39,1.26,1.39,2.23,0,1.37-1.11,2.48-2.47,2.48s-2.47-1.11-2.47-2.48c0-.77.35-1.48.95-1.96,0,0,.04-.04.05-.04.14-.14.16-.36.05-.52-.13-.19-.39-.25-.6-.11l-.1.08c-.76.63-1.2,1.56-1.2,2.55,0,1.83,1.49,3.32,3.31,3.32Z"/>
		</svg>
	);
};

SvgEmailResend.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgEmailResend;
