import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgLocation = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M12 2c3.87 0 7 3.267 7 7.286 0 2.2-.782 3.93-2.468 6.187l-.5.653-.72.926-.404.544c-.45.626-.745 1.13-.92 1.6-.1.267-.16.52-.18.765l-.008.182c0 1.02-.801 1.857-1.8 1.857-.95 0-1.72-.755-1.794-1.706l-.006-.151c0-.302-.063-.613-.188-.947-.154-.41-.399-.849-.759-1.37L9 17.468l-.313-.417-.984-1.268C5.855 13.373 5 11.587 5 9.286 5 5.266 8.129 2 12 2zm0 1C8.69 3 6 5.81 6 9.286c0 1.935.702 3.49 2.267 5.587l1.216 1.575.324.431.097.134c.497.69.832 1.265 1.045 1.832.165.44.25.87.25 1.298 0 .478.364.857.8.857.404 0 .745-.323.794-.749l.007-.108c0-.428.086-.857.25-1.298.213-.567.549-1.141 1.046-1.832l.305-.413 1.102-1.424C17.229 12.924 18 11.314 18 9.286 18 5.81 15.308 3 12 3zm0 3.5a2.5 2.5 0 1 1-.001 5.001A2.5 2.5 0 0 1 12 6.5zm0 1a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 12 7.5z" />
		</svg>
	);
};

SvgLocation.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgLocation;
