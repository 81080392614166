import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useKeycodeUtils } from '../../../../utils/keycodes/keycodes';

import TooltipPopup from 'components/Atoms/Tooltip/TooltipPopup';
import SvgQuestionmarkCircle from 'components/Atoms/SVG/Icons/SvgQuestionmarkCircle';

import styles from './TooltipIcon.module.scss';

const TooltipIcon = (props) => {
	const id = uuid();

	const { getTab } = useKeycodeUtils();

	const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

	const handleKeyDownTooltip = (event) => {
		if (getTab(event)) {
			setTooltipIsOpen(false);
		}
	};

	return (
		<span
			className={styles.icon}
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			<button
				className={styles.iconTooltip}
				tabIndex={props.tabindex}
				onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
				onKeyDown={handleKeyDownTooltip}
				aria-describedby={id}
				aria-expanded={tooltipIsOpen}
				aria-controls={id}
			>
				<TooltipPopup
					content={props.text}
					isOpen={tooltipIsOpen}
					setIsOpen={setTooltipIsOpen}
					id={id}
				>
					<SvgQuestionmarkCircle />
				</TooltipPopup>
			</button>
		</span>
	);
};

TooltipIcon.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	tabindex: PropTypes.number
};

export default TooltipIcon;
