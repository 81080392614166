import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { useRefUtils } from 'utils/refs/refs';

import HelperText from 'components/Atoms/Form/HelperText/HelperText';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';

import textInputStyles from 'components/Atoms/Form/TextInput/TextInput.module.scss';
import styles from './RadioBoxInput.module.scss';

const RadioBoxInput = (props) => {
	const { focusRef } = useRefUtils();

	const [id] = useState(uuid());
	const [hasFocus, setHasFocus] = useState(false);

	const inputRef = useRef(null);

	const hasBanner = useMemo(() => {
		let banner = false;
		if (props.items && props.items.length > 0) {
			const index = props.items.findIndex((item) => !!item.banner);
			banner = index > -1;
		}
		return banner;
	}, [props.items]);

	const onChange = (event) => {
		props.setValue(event.target.value);
	};

	return (
		<fieldset className={classNames(textInputStyles.wrapper, styles.wrapper)}>
			{props.label && (
				<legend className={classNames(textInputStyles.labelWrapper, styles.label)}>
					<span className={textInputStyles.label}>{props.label}{props.required ? '*' : ''}</span>
					{props.tooltip && (
						<span className={textInputStyles.tooltip}>
							<TooltipIcon
								text={props.tooltip}
								tabindex={props.tooltipTabindex}
							/>
						</span>
					)}
				</legend>
			)}
			<div
				className={classNames(
					styles.items,
					props.hasEqualHeight ? styles.hasEqualHeight : null,
					hasFocus ? styles.itemsHasFocus : null,
					!props.value ? styles.noSelection : null
				)}
			>
				{props.items && props.items.length > 0 && (
					<>
						{props.items.map((item, index) => (
							<div
								key={id + '-' + index}
								className={classNames(
									styles.itemWrapper,
									hasBanner && !item.banner ? styles.hasBannerPlaceholder : null
								)}
							>
								{hasBanner && !item.banner && (
									<div className={styles.bannerPlaceholder} />
								)}
								<input
									ref={index === 0 ? inputRef : null}
									type="radio"
									id={id + '-' + index}
									name={id}
									className={classNames(
										styles.input,
										props.hasError ? styles.error : null,
										props.hasError ? 'input--error' : null,
										hasFocus ? styles.hasFocus : null
									)}
									value={item.value}
									onChange={onChange}
									onFocus={() => setHasFocus(true)}
									onBlur={() => setHasFocus(false)}
									checked={props.value + '' === item.value + ''}
									disabled={props.disabled}
									required={props.required}
									onInvalid={(event) => event.preventDefault()}
									tabIndex={props.tabindex}
									data-testid={props.testId + '-' + index ?? null}
									aria-describedby={props.hasError ? `error-${id}` : null}
								/>
								<div
									className={classNames(
										styles.item,
										item.banner ? styles.hasBanner : null
									)}
									onClick={
										!props.disabled
											? () => {
												focusRef(inputRef);
												props.setValue(item.value + '');
											}
											: null
									}
									style={{ pointerEvents: props.infoModalIsOpen ? 'none' : 'auto' }}
								>
									{item.banner && (
										<div className={styles.banner}>{item.banner}</div>
									)}
									<div className={styles.itemInner}>
										<div
											className={classNames(
												styles.radio,
												props.value + '' === item.value + ''
													? styles.checked
													: null
											)}
										>
											<span className={styles.innerRadio} />
										</div>
										<div className={styles.content}>{item.content}</div>
									</div>
								</div>
							</div>
						))}
					</>
				)}
			</div>
			<HelperText
				id={`error-${id}`}
				type={props.hasError ? 'error' : 'light'}
				message={props.message}
				hasIcon
			/>
		</fieldset>
	);
};

RadioBoxInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	items: PropTypes.array,
	hasEqualHeight: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltipTabindex: PropTypes.number,
	testId: PropTypes.string,
	infoModalIsOpen: PropTypes.bool
};

export default RadioBoxInput;
