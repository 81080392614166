import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgAddCircle = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width}
			height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0 1c-4.962 0-9 4.038-9 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9zm0 3a.5.5 0 0 1 .492.41l.008.09v5h5a.5.5 0 0 1 .492.41L18 12a.5.5 0 0 1-.41.492l-.09.008h-5v5a.5.5 0 0 1-.41.492L12 18a.5.5 0 0 1-.492-.41l-.008-.09v-5h-5a.5.5 0 0 1-.492-.41L6 12a.5.5 0 0 1 .41-.492l.09-.008h5v-5a.5.5 0 0 1 .41-.492L12 6z" />
		</svg>
	);
};

SvgAddCircle.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgAddCircle;
