import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useScrollUtils } from 'utils/scroll/scroll';
import { useLegitimationApi } from 'api/legitimation';

import { Link, Text, Title } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import { CheckboxInput, RadioBoxInput } from 'components/Atoms/Form';
import RadioBoxItem from 'components/Atoms/Form/RadioBoxInput/RadioBoxItem';
import SvgStopwatch15 from 'components/Atoms/SVG/Icons/SvgStopwatch15';
import SvgStopwatch5 from 'components/Atoms/SVG/Icons/SvgStopwatch5';
import Button from 'components/Atoms/Button/Button';
import SvgShare from 'components/Atoms/SVG/Icons/SvgShare';
import DownloadContracts from 'components/Atoms/Partials/DownloadContracts/DownloadContracts';
import Modal from 'components/Atoms/Modal/Modal';

import SvgVideoconference from 'components/Atoms/SVG/Icons/SvgVideoconference';

import { useSelector } from 'react-redux';

import styles from './Identification.module.scss';
import titleStyles from 'components/Atoms/Text/Title/Title.module.scss';

const Identification = () => {
	const { getCurrentFlow, isCreditSubType, isStaticSubType, isCreditOrCreditCardSubType, isFeatureEnabledFor } = useUtils();
	const { goToPage, preventHistoryBackFor } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { scrollTo } = useScrollUtils();
	const { getFeatures, postRegisterWithWebid } = useLegitimationApi();

	const currentFlow = getCurrentFlow();

	const storeHasSecondApplicant = useSelector((state => state.secondApplicant.hasSecondApplicant));
	const storeCurrentApplicant = useSelector((state => state.applicant.currentApplicant));
	const storeSkipLegi1 = useSelector((state => state.app.skipLegiLP1));
	const storeSkipLegi2 = useSelector((state => state.app.skipLegiLP2));

	const digitalIdentItem = {
		value: 'digital-ident',
		content: (
			<RadioBoxItem
				title={m('pages.identification.digitalIdent.title', 'global')}
				icon={<SvgVideoconference />}
				description={m('pages.identification.digitalIdent.subtitle', 'global')}
				list={m('pages.identification.digitalIdent.list', 'global', null, true)}
				ariaLabelActive="Details zur digitale Unterschrift schließen"
				ariaLabelInactive="Details zur digitale Unterschrift öffnen"
				ariaControls="digital-ident-wrapper"
				toggleButtonTabindex={4}
			/>
		)
	};
	const videoIdentItem = {
		value: 'video-ident',
		content: (
			<RadioBoxItem
				title={m('identification.videoIdent.title', currentFlow)}
				icon={<SvgStopwatch15 />}
				description={m('identification.videoIdent.subtitle', currentFlow)}
				list={m('identification.videoIdent.list', currentFlow, null, true)}
				toggleButtonText={m('identification.videoIdent.toggleButton', currentFlow)}
				toggleContent={m('identification.videoIdent.toggleText', currentFlow, null, true)}
				ariaLabelActive="Details zum Video-Ident schließen"
				ariaLabelInactive="Details zum Video-Ident öffnen"
				ariaControls="video-ident-wrapper"
				toggleButtonTabindex={3}
			/>
		)
	};
	const kontoIdentItem = {
		value: 'konto-ident',
		content: (
			<RadioBoxItem
				title={m('identification.kontoIdent.title', currentFlow)}
				icon={<SvgStopwatch5 />}
				description={m('identification.kontoIdent.subtitle', currentFlow)}
				list={m('identification.kontoIdent.list', currentFlow, null, true)}
				toggleButtonText={m('identification.kontoIdent.toggleButton', currentFlow)}
				toggleContent={m('identification.kontoIdent.toggleText', currentFlow, null, true)}
				ariaLabelActive="Details zum Konto-Ident schließen"
				ariaLabelInactive="Details zum Konto-Ident öffnen"
				ariaControls="konto-ident-wrapper"
				toggleButtonTabindex={2}
			/>
		)
	};

	const [identificationType, setIdentificationType] = useState(null);
	const [identificationTypeItems, setIdentificationTypeItems] = useState([]);
	const [dataRedirectionWebId, setDataRedirectionWebId] = useState(false);
	const [termsAndConditionsWebId, setTermsAndConditionsWebId] = useState(false);

	const [identificationTypeError, setIdentificationTypeError] = useState(null);
	const [dataRedirectionWebIdError, setDataRedirectionWebIdError] = useState(null);
	const [termsAndConditionsWebIdError, setTermsAndConditionsWebIdError] = useState(null);

	const [hasPostidentDigital, setHasPostidentDigital] = useState(false);
	const [hasPostidentCoupon, setHasPostidentCoupon] = useState(false);

	const [infoModalIsOpenn, setInfoModalIsOpen] = useState(false);

	const checkboxAnchorRef = useRef(null);

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
		loadFeatures();
	}, []);

	const loadFeatures = async () => {
		let features = null;
		if (isCreditSubType()) {
			if (
				storeHasSecondApplicant &&
				storeCurrentApplicant === 2 &&
				storeSkipLegi2
			) {
				features = { digitalident: true };
			} else if (storeSkipLegi1) {
				features = { digitalident: true };
			} else {
				features = await getFeaturesCall();
			}
		} else if (currentFlow === C.FRONTEND.VISACARD) {
			features = {
				digitalident: true,
				postidentCoupon: true
			};
		} else {
			features = await getFeaturesCall();
		}
		setIdentificationTypeItemsByFeatures(features);
		if (currentFlow === C.FRONTEND.GIRO) {
			setInfoModalIsOpen(true);
		}
	};

	const getFeaturesCall = async () => {
		let tempFeatures = null;
		await getFeatures(
			(features) => {
				tempFeatures = features;
			},
			() => {}
		);
		return tempFeatures;
	};

	const setIdentificationTypeItemsByFeatures = (features = null) => {
		const items = [];
		if (features) {
			if (features.digitalident) {
				items.push(digitalIdentItem);
				if (features.postidentCoupon) {
					setHasPostidentCoupon(true);
				}
			} else {
				if (isFeatureEnabledFor(features.kontoident) && !storeHasSecondApplicant) {
					items.push(kontoIdentItem);
				}
				if (isFeatureEnabledFor(features.webId)) {
					items.push(videoIdentItem);
				}
				if (isFeatureEnabledFor(features.postidentDigital)) {
					setHasPostidentDigital(true);
				}
				if (isFeatureEnabledFor(features.postidentCoupon)) {
					setHasPostidentCoupon(true);
				}
			}
		}
		if(items.length === 1) {
			setIdentificationType(items[0].value);
		}
		setIdentificationTypeItems(items);
	};

	useEffect(() => {
		scrollToCheckboxes();
	}, [identificationType]);

	const clearErrors = () => {
		setIdentificationTypeError(null);
		setDataRedirectionWebIdError(null);
		setTermsAndConditionsWebIdError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(identificationType)) {
			setIdentificationTypeError(m('validation.error.requiredNamed', 'fields', { name: m('identificationType.label', 'fields') }));
			hasEmptyFields = true;
		}
		if (isEmpty(dataRedirectionWebId)) {
			setDataRedirectionWebIdError(m('validation.error.requiredNamed', 'fields', { name: m('dataRedirectionWebId.label', 'fields') }));
			hasEmptyFields = true;
		}
		if (isEmpty(termsAndConditionsWebId)) {
			setTermsAndConditionsWebIdError(m('validation.error.requiredNamed', 'fields', { name: m('termsAndConditionsWebId.label', 'fields') }));
			hasEmptyFields = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			await postRegisterWithWebid(
				identificationType === 'konto-ident',
				(data) => {
					continueValid();
					if (storeCurrentApplicant === 2 && data.webIdRedirectUrlForParticipant1) {
						window.location.href = data.webIdRedirectUrlForParticipant2;
					} else {
						window.location.href = data.webIdRedirectUrlForParticipant1;
					}
				},
				() => {
					continueInvalid();
					if (isCreditSubType()) {
						goToPage(currentFlow + C.ROUTES.ERROR_TECHNICAL);
					} else {
						goToPage(currentFlow + C.ROUTES.THANKYOU);
					}
				}
			);
		} else {
			onInvalid();
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const scrollToCheckboxes = () => {
		let offset = -70;
		if (window.innerWidth <= 576) {
			offset = 0;
		}
		scrollTo(checkboxAnchorRef, offset);
	};

	const submitExtension = () => {
		if (
			(hasPostidentDigital || hasPostidentCoupon)
			&& !(storeHasSecondApplicant && storeCurrentApplicant === 2)
		) {
			return (
				<div className={styles.postidentExtension}>
					<Text>
						{m('pages.identification.submitExtension1', 'global')}{' '}
						<Link onClick={onClickPostident} tabindex={10}>
							{m('pages.identification.submitExtension2', 'global')}
						</Link>{' '}
						{m('pages.identification.submitExtension3', 'global')}
					</Text>
				</div>
			);
		} else {
			return null;
		}
	};

	const onClickPostident = () => {
		continueValid();

		if(hasPostidentDigital) {
			goToPage(currentFlow + C.ROUTES.DIGITAL_POSTIDENT_CONFIRMATION);
		} else {
			goToPage(currentFlow + C.ROUTES.POSTIDENT);
		}
	};

	const title = useMemo(() => {
		if (isCreditSubType() && storeSkipLegi1) return m('pages.identification.titleLegiSkip', 'global');
		if (isStaticSubType()) return m('pages.identification.titleShort', 'global');
		return m('pages.identification.title', 'global');
	}, [isCreditSubType, storeSkipLegi1, isStaticSubType]);

	return (
		<>
			<Title mb>{title}</Title>
			<Text size="l" mb>{m('identification.subtitle', currentFlow)}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={9}
				submitExtension={submitExtension()}
				prevButtonExtension={isCreditOrCreditCardSubType(null, [C.FRONTEND.VISACARD]) ? <DownloadContracts submitTabindex={10} /> : null}
			>
				<RadioBoxInput
					value={identificationType}
					setValue={setIdentificationType}
					items={identificationTypeItems}
					label={m('identificationType.labelLong', 'fields')}
					hasError={!!identificationTypeError}
					message={identificationTypeError}
					tabindex={1}
					testId="identification-type"
					required
				/>

				<div className={styles.anchorCheckboxes} ref={checkboxAnchorRef} />

				<CheckboxInput
					value={dataRedirectionWebId}
					setValue={setDataRedirectionWebId}
					label={m('pages.identification.dataRedirectionWebId', 'global')}
					hasError={!!dataRedirectionWebIdError}
					message={dataRedirectionWebIdError}
					tabindex={5}
					testId="data-redirection-web-id"
					required
				/>

				<CheckboxInput
					value={termsAndConditionsWebId}
					setValue={setTermsAndConditionsWebId}
					label={
						<>
							{m('pages.identification.termsAndConditionsWebIdLabel1', 'global')}
							<Link href="https://www.webid-solutions.de/agb" target="_blank" tabindex={7}>
								{m('pages.identification.termsAndConditionsWebIdLinkText1', 'global')}
								<SvgShare />
							</Link>
							{m('pages.identification.termsAndConditionsWebIdLabel2', 'global')}
							<Link href="https://www.webid-solutions.de/datenschutzbestimmungen" target="_blank" tabindex={8}>
								{m('pages.identification.termsAndConditionsWebIdLinkText2', 'global')}
								<SvgShare />
							</Link>
							{m('pages.identification.termsAndConditionsWebIdLabel3', 'global')}
						</>
					}
					hasError={!!termsAndConditionsWebIdError}
					message={termsAndConditionsWebIdError}
					tabindex={6}
					testId="terms-and-conditions-web-id"
					required
				/>

			</Form>
			{currentFlow === C.FRONTEND.GIRO && (
				<Modal
					isOpen={infoModalIsOpenn}
					onClose={() => { setInfoModalIsOpen(false); }}
					tabindexClose={302}
					setFocusToTabindexOnOpen={300}
					setFocusToTabindexOnClose={1}
				>
					<p className={classNames(titleStyles.title, titleStyles.s, titleStyles.mb)}>
						{m('pages.identification.infoModal.title', 'global')}
					</p>
					<Text>
						{m('pages.identification.infoModal.description', 'global', null, true)}
					</Text>
					<div className="text-align-right mt--30">
						<Button
							onClick={() => { setInfoModalIsOpen(false); }}
							fullWidthMobile
							tabindex={301}
						>
							{m('pages.identification.infoModal.button', 'global')}
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
};
export default Identification;
