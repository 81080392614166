import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useFlowUtils } from 'utils/flow/flow';

import { Text } from 'components/Atoms/Text';
import Button from 'components/Atoms/Button/Button';
import Modal from 'components/Atoms/Modal/Modal';
import SvgLogo from 'components/Atoms/SVG/Logo/SvgLogo';
import SvgLogoIcon from 'components/Atoms/SVG/Logo/SvgLogoIcon';
import StickyHeaderMobile from 'components/Organisms/Header/StickyHeaderMobile/StickyHeaderMobile';
import PartnerLogo from 'components/Organisms/Header/PartnerLogo/PartnerLogo';
import SecureConnection from 'components/Atoms/Partials/SecureConnection/SecureConnection';
import CreditInfos from 'components/Organisms/Header/CreditInfos/CreditInfos';

import { useDispatch, useSelector } from 'react-redux';
import { setSidebarActive } from 'store/App/actions';

import styles from './Header.module.scss';
import titleStyles from '../../Atoms/Text/Title/Title.module.scss';

const Header = props => {
	const { getCurrentFlow, isCreditSubType } = useUtils();
	const { isGmkHyundai } = useFlowUtils();

	const dispatch = useDispatch();
	const storeHideTitle = useSelector((state => state.app.hideTitle));
	const storeSalutation = useSelector((state => state.name.salutation));
	const storeFirstName = useSelector((state => state.name.firstName));
	const storeLastName = useSelector((state => state.name.lastName));

	const currentFlow = getCurrentFlow();

	const [logoPopUpIsOpen, setLogoPopUpIsOpen] = useState(false);

	const openSidebar = () => {
		dispatch(setSidebarActive(true));
		window.scrollTo(0, 0);
	};

	const title = useMemo(() => {
		if (isGmkHyundai)
			return m('hyundai.title', currentFlow);
		return m('title', currentFlow);
	}, [isGmkHyundai]);

	return (
		<>
			<header className={styles.headerOuter}>
				<div className={styles.headerWrapper}>
					<div
						className={classNames(styles.logo, props.hideFlowTitle || storeHideTitle ? styles.logoLoading : null)}
						onClick={() => { setLogoPopUpIsOpen(true); }}
					>
						<div className={styles.isDesktop}>
							<SvgLogo />
						</div>
						<div className={styles.isMobile}>
							<SvgLogoIcon />
						</div>
					</div>

					{!props.hideFlowTitle && !storeHideTitle && (
						<div className={styles.headerHeadline}>
							<p className={styles.headerTitle}>
								<strong>{title}</strong>
								<br />
								<>
									{storeSalutation && storeFirstName && storeLastName
										? m('salutation.for', 'fields', {
											salutation:
												storeSalutation === 'FEMALE'
													? m('salutation.items.female', 'fields')
													: m(
														'salutation.items.maleExt',
														'fields'
													),
											firstName: storeFirstName,
											lastName: storeLastName
										})
										: ''}
								</>
							</p>
						</div>
					)}

					{isCreditSubType() && props.hasRightSidebar && (
						<nav className={styles.creditInfosDesktop}>
							<CreditInfos openSidebar={openSidebar} />
						</nav>
					)}

					<PartnerLogo />
				</div>

				{isCreditSubType() && props.hasRightSidebar && (
					<StickyHeaderMobile>
						<div className={styles.creditInfosMobile}>
							<CreditInfos openSidebar={openSidebar} />
						</div>
					</StickyHeaderMobile>
				)}
				<div className={styles.secureConnection}>
					<SecureConnection />
				</div>
			</header>
			<Modal
				isOpen={logoPopUpIsOpen}
				onClose={() => {
					setLogoPopUpIsOpen(false);
				}}
				tabindexClose={401}
				setFocusToTabindexOnOpen={400}
				setFocusToTabindexOnClose={1}
			>
				{
					<div className="text-align-center">
						<p className={classNames(titleStyles.title, titleStyles.s, titleStyles.mb)}>
							<strong>{m('logoExitPopup.title', 'global')}</strong>
						</p>
						<Text>
							{m('logoExitPopup.description', 'global')}
						</Text>
						<div className="mt--30">
							<Button
								link="https://www.santander.de/privatkunden"
								fullWidthMobile
								tabindex={400}
							>
								{m('logoExitPopup.button', 'global')}
							</Button>
						</div>
					</div>
				}
			</Modal>
		</>
	);
};

Header.propTypes = {
	hasRightSidebar: PropTypes.bool,
	hideFlowTitle: PropTypes.bool
};

export default Header;
