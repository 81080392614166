import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import Box from 'components/Molecules/Box/Box';
import { Text } from 'components/Atoms/Text';

import styles from './BoxSteps.module.scss';
import titleStyles from '../../Text/Title/Title.module.scss';

const BoxSteps = (props) => {
	const id = uuid();
	return (
		<>
			{props.items?.length > 0 && (
				<div className={styles.wrapper}>
					{props.items.map((item, index) => (
						<div
							key={`${id}-box-${index}`}
							className={styles.item}
						>
							<Box border borderRadius>
								<div className={styles.content}>
									<div className={styles.number}>
										{index + 1}
									</div>
									<div className={styles.text}>
										{item.title && <p className={classNames(titleStyles.title, titleStyles.s)}>{item.title}</p>}
										{item.subtitle && <Text>{item.subtitle}</Text>}
									</div>
									{item.icon && (
										<div className={styles.icon} aria-hidden="true">
											{item.icon}
										</div>
									)}
								</div>
							</Box>
						</div>
					))}
				</div>
			)}
		</>
	);
};

BoxSteps.propTypes = {
	items: PropTypes.array
};

export default BoxSteps;
