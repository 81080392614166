import React, { useEffect, useState } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useInsuranceUtils } from 'utils/insurance/insurance';

import { Col, Row } from 'components/Atoms/Grid';
import Form from 'components/Molecules/Form/Form';
import { Text, Title } from 'components/Atoms/Text';
import { RadioButtonInput } from 'components/Atoms/Form';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setSidebarActive, setSidebarAnimation, setIsShowRedemption } from 'store/App/actions';
import { setExternalCredits } from 'store/ExternalCredit/actions';

const ExternalCredit = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput } = useValidationUtils();
	const { showInsurancePage } = useInsuranceUtils();

	const hasExternalCreditItems = [
		{ label: 'Ja', value: 'true' },
		{ label: 'Nein', value: 'false' }
	];

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeDacStatus = useSelector((state => state.digitalAccountCheck.status));
	const storeExternalCredits = useSelector((state => state.externalCredit.externalCredits));
	const storeIsShowRedemption = useSelector((state => state.app.isShowRedemption));

	const [hasExternalCredits, setHasExternalCredits] = useState(null);

	const [hasExternalCreditsError, setHasExternalCreditsError] = useState(null);

	useEffect(() => {
		if (
			storeExternalCredits &&
			storeExternalCredits.length > 0 &&
			!isNaN(storeExternalCredits[0].remainingBalance) &&
			storeExternalCredits[0].remainingBalance > 0
		) {
			setHasExternalCredits(hasExternalCreditItems[0].value);
		} else {
			setHasExternalCredits(hasExternalCreditItems[1].value);
		}
	}, []);

	useEffect(() => {
		if (storeIsShowRedemption) {
			setHasExternalCredits(hasExternalCreditItems[0].value);
		} else {
			setHasExternalCredits(hasExternalCreditItems[1].value);
		}
	}, [storeIsShowRedemption]);

	useEffect(() => {
		if (hasExternalCredits === 'true') {
			dispatch(setSidebarActive(true));
			dispatch(setSidebarAnimation(true));
			setTimeout(() => {
				dispatch(setSidebarAnimation(false));
			}, 3000);
		} else {
			dispatch(setExternalCredits([]));
			dispatch(setIsShowRedemption(false));
		}
	}, [hasExternalCredits]);

	const clearErrors = () => {
		setHasExternalCreditsError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;

		if (isEmpty(hasExternalCredits)) {
			setHasExternalCreditsError(m('validation.error.requiredNamed', 'fields', { name: m('hasExternalCredits.label', 'fields') }));
			hasEmptyFields = true;
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		if(showInsurancePage()) {
			goToPage(currentFlow + C.ROUTES.INSURANCE);
		} else {
			goToPage(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
		}
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const autofill = () => {
		setHasExternalCredits('false');
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Row>
				<Col grow>
					<Title mb>{m('pages.externalCredit.title', 'global')}</Title>
				</Col>
				<Col noGrow>
					<div className="mt--5">
						<TooltipIcon
							text={<>{m('pages.externalCredit.tooltip', 'global')}</>}
							inTitleXl
							tabindex={1}
						/>
					</div>
				</Col>
			</Row>
			<Text size="l" mb>{m('pages.externalCredit.subtitle', 'global')}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={3}
				onPrevButton={
					storeDacStatus !== C.DAC_STATUS.GREEN
						? () => { goToPage(currentFlow + C.ROUTES.FINANCIAL_STATUS_DAC); }
						: null
				}
			>
				<RadioButtonInput
					value={hasExternalCredits}
					setValue={setHasExternalCredits}
					items={hasExternalCreditItems}
					label={m('externalCredit.label', 'fields')}
					hasError={!!hasExternalCreditsError}
					message={hasExternalCreditsError}
					tabindex={2}
					testId="has-external-credits"
					required
				/>
			</Form>
		</>
	);
};
export default ExternalCredit;
