import C from 'constants/Constants';

import Login from 'components/Pages/Registration/Login/Login';
import Name from 'components/Pages/Registration/Name/Name';
import Birthdate from 'components/Pages/Registration/Birthdate/Birthdate';
import Email from 'components/Pages/Registration/Email/Email';
import EmailSent from 'components/Pages/Registration/EmailSent/EmailSent';
import EmailConfirmedOld from 'components/Pages/Registration/EmailConfirmed/EmailConfirmed';
import EmailNotConfirmed from 'components/Pages/Registration/EmailNotConfirmed/EmailNotConfirmed';
import MobileNumber from 'components/Pages/Registration/MobileNumber/MobileNumber';
import Otp from 'components/Pages/Registration/Otp/Otp';
import Nationality from 'components/Pages/ApplicationData/Nationality/Nationality';
import Address from 'components/Pages/ApplicationData/Address/Address';
import MaritalStatus from 'components/Pages/ApplicationData/MaritalStatus/MaritalStatus';
import Occupation from 'components/Pages/ApplicationData/Occupation/Occupation';
import BankAccount from 'components/Pages/ApplicationData/BankAccount/BankAccount';
import TermsAndConditions from 'components/Pages/Application/TermsAndConditions/TermsAndConditions';
import Identification from 'components/Pages/Legitimation/Identification/Identification';
import PostIdent from 'components/Pages/Legitimation/PostIdent/PostIdent';
import DigitalPostidentConfirmation from 'components/Pages/Legitimation/DigitalPostidentConfirmation/DigitalPostidentConfirmation';
import DigitalPostident from 'components/Pages/Legitimation/DigitalPostident/DigitalPostident';
import WebIdDone from 'components/Pages/Legitimation/WebIdDone/WebIdDone';
import AddressSelection from 'components/Pages/Legitimation/AddressSelection/AddressSelection';
import AddressVerification from 'components/Pages/Legitimation/AddressVerification/AddressVerification';
import AddressVerificationPostponed from 'components/Pages/Legitimation/AddressVerificationPostponed/AddressVerificationPostponed';
import AccountSuccess from 'components/Pages/Done/Success/Account/AccountSuccess';
import Tin from 'components/Pages/ApplicationData/Tin/Tin';
import AccountExists from 'components/Pages/Done/Failure/AccountExists/AccountExists';
import ThankYou from 'components/Pages/Application/ThankYou/ThankYou';
import ThankYouGmk from 'components/Pages/Application/ThankYou/ThankYouGmk';
import ThankYouPep from 'components/Pages/Application/ThankYou/ThankYouPep';

export const routes = (flow, gtmType) => {
	let routes = [];
	routes = routes.concat([
		{
			subpage: C.ROUTES.LOGIN,
			component: Login,
			gtmStep: 'login',
			gtmStatus: 'login_pending',
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.INDEX,
			component: Name,
			stepCategory: 1,
			gtmStep: 'userdata_name',
			gtmStatus: 'registration_pending',
			showDurationHint: true,
			showRequiredFieldsHint: true,
			hasExitWindowPopup: true
		},
		{
			subpage: C.ROUTES.BIRTHDATE,
			component: Birthdate,
			stepCategory: 1,
			gtmStep: 'userdata_birthdate',
			gtmStatus: 'registration_pending',
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.EMAIL,
			component: Email,
			stepCategory: 1,
			gtmStep: 'userdata_email',
			gtmStatus: 'registration_pending',
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.EMAIL_SENT,
			component: EmailSent,
			stepCategory: 1,
			gtmStep: 'userdata_email_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'email_confirmation_pending'
		},
		{
			subpage: C.ROUTES.EMAIL_CONFIRMED + '/:accessToken',
			component: EmailConfirmedOld,
			stepCategory: 1,
			gtmStep: 'userdata_email_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'email_confirmed'
		},
		{
			subpage: C.ROUTES.EMAIL_NOT_CONFIRMED,
			component: EmailNotConfirmed,
			stepCategory: 1,
			gtmStep: 'userdata_email_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'email_not_confirmed'
		},
		{
			subpage: C.ROUTES.MOBILE_NUMBER,
			component: MobileNumber,
			stepCategory: 1,
			gtmStep: 'userdata_mobile_number',
			gtmStatus: 'registration_pending',
			needsRegistrationToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.OTP,
			component: Otp,
			stepCategory: 1,
			gtmStep: 'userdata_mobile_number_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'mobile_number_confirmation_pending',
			needsRegistrationToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.OTP + '/:isRegistration',
			component: Otp,
			stepCategory: 1,
			gtmStep: 'userdata_mobile_number_confirmation',
			gtmStatus: 'registration_pending',
			gtmSubStatus: 'mobile_number_confirmation_pending',
			needsRegistrationToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.NATIONALITY,
			component: Nationality,
			stepCategory: 2,
			gtmStep: 'personaldata_nationality',
			gtmStatus: 'application_pending',
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.ADDRESS,
			component: Address,
			stepCategory: 2,
			gtmStep: 'personaldata_address',
			gtmStatus: 'application_pending',
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.MARITAL_STATUS,
			component: MaritalStatus,
			stepCategory: 2,
			gtmStep: 'personaldata_marital_status',
			gtmStatus: 'application_pending',
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.OCCUPATION,
			component: Occupation,
			stepCategory: 2,
			gtmStep: 'personaldata_occupation',
			gtmStatus: 'application_pending',
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.BANK_ACCOUNT,
			component: BankAccount,
			stepCategory: 2,
			gtmStep: 'personaldata_current_account',
			gtmStatus: 'application_pending',
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.TIN,
			component: Tin,
			stepCategory: 2,
			gtmStep: 'personaldata_tax_information',
			gtmStatus: 'application_pending',
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.TERMS_AND_CONDITIONS,
			component: TermsAndConditions,
			stepCategory: 3,
			gtmStep: 'personaldata_terms_conditions',
			gtmStatus: 'application_pending',
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.IDENTIFICATION,
			component: Identification,
			gtmStep: 'identification_selection',
			gtmStatus: 'identification_pending',
			needsVcJwtToken: true,
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.POSTIDENT,
			component: PostIdent,
			gtmStep: 'postident',
			gtmStatus: 'identification_pending',
			needsVcJwtToken: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.DIGITAL_POSTIDENT_CONFIRMATION,
			component: DigitalPostidentConfirmation,
			gtmStep: 'digital_postident_confirmation',
			gtmStatus: 'identification_pending',
			needsVcJwtToken: true,
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.DIGITAL_POSTIDENT,
			component: DigitalPostident,
			gtmStep: 'digital_postident',
			gtmStatus: 'identification_pending',
			needsVcJwtToken: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.WEB_ID_DONE,
			component: WebIdDone,
			hideFlowTitle: true
		},
		{
			subpage: C.ROUTES.ADDRESS_SELECTION,
			component: AddressSelection,
			gtmStep: 'address_selection',
			gtmStatus: 'identification_pending',
			gtmIdentificationDocument: 'idcard',
			needsVcJwtToken: true,
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.ADDRESS_VERIFICATION,
			component: AddressVerification,
			gtmStep: 'address_verification',
			gtmStatus: 'identification_pending',
			gtmSubStatus: 'upload_pending',
			gtmIdentificationDocument: 'idcard',
			needsVcJwtToken: true,
			needsAuthToken: true,
			showRequiredFieldsHint: true
		},
		{
			subpage: C.ROUTES.ADDRESS_VERIFICATION_POSTPONED,
			component: AddressVerificationPostponed,
			gtmStep: 'address_verification_postponed',
			gtmStatus: 'identification_pending',
			gtmSubStatus: 'address_verification_pending',
			gtmIdentificationDocument: 'idcard',
			needsVcJwtToken: true,
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.ACCOUNT_SUCCESS,
			component: AccountSuccess,
			gtmStep: 'giro_success',
			gtmStatus: 'giro_success',
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.GMK_EXISTS,
			component: AccountExists,
			gtmStep: 'account_exists',
			gtmStatus: 'application_end',
			needsAuthToken: true
		},
		{
			subpage: C.ROUTES.THANKYOU2,
			component: ThankYouGmk,
			gtmStep: 'thank_you',
			gtmStatus: 'application_end'
		},
		{
			subpage: C.ROUTES.THANKYOU,
			component: ThankYou,
			gtmStep: 'thank_you',
			gtmStatus: 'application_end'
		},
		{
			subpage: C.ROUTES.THANKYOU_PEP,
			component: ThankYouPep,
			gtmStep: 'thank_you_pep',
			gtmStatus: 'application_end'
		}
	]);

	for (const route of routes) {
		route.flow = flow;
		route.gtmType = gtmType;
	}

	return routes;
};
export default routes;
