import React from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useLegitimationApi } from 'api/legitimation';

import { Title, Text } from 'components/Atoms/Text';
import Form from 'components/Molecules/Form/Form';
import DownloadContracts from 'components/Atoms/Partials/DownloadContracts/DownloadContracts';
import SvgDownload from 'components/Atoms/SVG/Icons/SvgDownload';
import Button from 'components/Atoms/Button/Button';

const PostIdent = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid } = useTrackingUtils();
	const { getPostidentCoupon } = useLegitimationApi();

	const currentFlow = getCurrentFlow();

	const getCoupon = async () => {
		await getPostidentCoupon(
			() => {},
			() => {}
		);
	};

	const onSubmit = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.IDENTIFICATION);
	};

	return (
		<>
			<Title mb>{m('pages.postIdent.title', 'global')}</Title>
			<Text size="l" mb>{m('pages.postIdent.subtitle', 'global')}</Text>
			<Button
				buttonStyle="link"
				onClick={getCoupon}
				prependIcon={<SvgDownload />}
				tabindex={1}
			>
				{m('pages.postIdent.link.coupon', 'global')}
			</Button>
			<DownloadContracts submitTabindex={1} />
			<Form
				onSubmit={onSubmit}
				submitText={m('pages.postIdent.submit', 'global')}
				submitTabindex={4}
			/>
		</>
	);
};
export default PostIdent;
