import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgPhone = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M15.609 14.516a2.247 2.247 0 0 1 2.855-1.236l2.07.77A2.247 2.247 0 0 1 22 16.157v3.229A2.614 2.614 0 0 1 19.385 22C9.783 22 2 14.216 2 4.615A2.614 2.614 0 0 1 4.614 2h3.229A2.25 2.25 0 0 1 9.95 3.465l.77 2.071a2.248 2.248 0 0 1-1.235 2.855l-2.186.92c1.499 2.734 4.667 5.906 7.391 7.391l.919-2.186zm-.887 3.327l-.057-.028c-3.164-1.547-6.97-5.367-8.509-8.538a.5.5 0 0 1 .256-.679L9.097 7.47c.616-.26.919-.958.686-1.585l-.77-2.07A1.25 1.25 0 0 0 7.842 3H4.614C3.722 3 3 3.723 3 4.615 3 13.665 10.335 21 19.385 21c.892 0 1.615-.722 1.615-1.614v-3.229c0-.521-.324-.988-.813-1.17l-2.072-.77a1.247 1.247 0 0 0-1.584.686l-1.128 2.685a.5.5 0 0 1-.681.255z" />
		</svg>
	);
};

SvgPhone.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgPhone;
