import React from 'react';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useNumberUtils } from 'utils/number/number';

import { Text, Title } from 'components/Atoms/Text';
import { Row, Col } from 'components/Atoms/Grid';
import SvgIllustrationDone from 'components/Atoms/SVG/Illustrations/SvgIllustrationDone';

import { useSelector } from 'react-redux';

import styles from './CreditSuccess.module.scss';

const CreditSuccess = () => {
	const { getCurrentFlow } = useUtils();
	const { formatMoneyValue } = useNumberUtils();

	const currentFlow = getCurrentFlow();

	const storeAmount = useSelector((state => state.app.amount));
	const storeLoansAccountNumber = useSelector((state => state.newAccount.loansAccountNumber));

	return (
		<>
			<Row>
				<Col xs={12} sm={4} md={5}>
					<div className={styles.illustrationWrapper} aria-hidden="true">
						<SvgIllustrationDone />
					</div>
				</Col>
				<Col xs={12} sm={8} md={7}>
					<div className={styles.textGroup}>
						<Title mb>
							{m('loanSuccess.title', currentFlow)}
						</Title>
						{storeAmount && (
							<Title size="l" mb>
								{formatMoneyValue(storeAmount, true)} €
							</Title>
						)}
						<Text mb>{m('loanSuccess.subtitle', currentFlow)}</Text>

						{storeLoansAccountNumber && (
							<>
								<Title size="s">
									{m('loanSuccess.accountNr', currentFlow)}
								</Title>
								<Text size="l">
									{storeLoansAccountNumber}
								</Text>
							</>
						)}
					</div>
				</Col>
			</Row>
		</>
	);
};
export default CreditSuccess ;
