import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgCheckbox = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M21.5 11.492a.5.5 0 0 1 .492.41l.008.09v9.006a.5.5 0 0 1-.41.492l-.09.008h-18a.5.5 0 0 1-.09-.992l.09-.008H21v-8.506a.5.5 0 0 1 .5-.5zm-.574-8.57l.64.598c.578.579.578 1.517.003 2.093l-4.803 4.877 2.734.001a.5.5 0 0 1 .492.41l.008.09v8.006a.5.5 0 0 1-.41.492l-.09.008h-17a.5.5 0 0 1-.492-.41L2 18.997v-8.005a.5.5 0 0 1 .41-.493l.09-.008h8.792l7.553-7.557a1.475 1.475 0 0 1 2.08-.012zm-9.927 8.57H3v7.005h16v-7.005h-3.219l-1.822 1.852a.5.5 0 0 1-.357.15H11.5a.5.5 0 0 1-.5-.501v-1.502zm6.501 5.003a.5.5 0 0 1 .09.993l-.09.008h-3a.5.5 0 0 1-.09-.993l.09-.008h3zm0-2.001a.5.5 0 0 1 .09.993l-.09.008H4.509a.5.5 0 0 1-.09-.993l.09-.008h12.99zm-8.991-1.988a.5.5 0 0 1 .09.992l-.09.009h-4a.5.5 0 0 1-.09-.993l.09-.008h4zm11.043-8.864L12 11.199v1.294h1.394l7.466-7.585a.478.478 0 0 0 .012-.668l-.64-.599a.48.48 0 0 0-.679 0z" />
		</svg>
	);
};

SvgCheckbox.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgCheckbox;
