import React, { useEffect } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';

import { Text, Title } from 'components/Atoms/Text';
import PhoneLink from 'components/Atoms/Partials/PhoneLink/PhoneLink';
import Form from 'components/Molecules/Form/Form';

import { useSelector } from 'react-redux';

import styles from 'components/Pages/Application/ThankYou/ThankYouCredit.module.scss';

const ThankYouCredit2 = () => {
	const { getCurrentFlow } = useUtils();
	const { preventHistoryBackFor } = usePageUtils();
	const { ecommercePurchase } = useTrackingUtils();

	const currentFlow = getCurrentFlow();

	const storeBcoResults = useSelector((state => state.app.bcoResults));

	useEffect(() => {
		preventHistoryBackFor(currentFlow + C.ROUTES.TERMS_AND_CONDITIONS);
		ecommercePurchase();
	}, []);

	return (
		<Form>
			<div className={styles.wrapper}>
				<div className={styles.header} />
				<div className={styles.content}>
					<Title mb>{m('pages.thankYou2.title', 'global')}</Title>
					<Text mb>
						{m('pages.thankYou2.description2', 'global', null, true)}
					</Text>
					{storeBcoResults.applicationId && (
						<Text mb>
							<strong>
								{m('pages.thankYou2.yourNumber', 'global')}:
							</strong>{' '}
							{storeBcoResults.applicationId}
						</Text>
					)}
					<Text>
						{m('pages.thankYou2.hotlineDescription21', 'global', null, true)}
						<PhoneLink tabindex={1} />
						{m('pages.thankYou2.hotlineDescription22', 'global', null, true)}
					</Text>
				</div>
			</div>
		</Form>
	);
};
export default ThankYouCredit2;
