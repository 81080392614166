import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { m } from 'utils/messages/messages';

import { Col, Container, Row } from 'components/Atoms/Grid';
import LoadingSpinner from 'components/Atoms/Loading/Spinner/LoadingSpinner';
import SvgVideoconference from 'components/Atoms/SVG/Icons/SvgVideoconference';
import SvgCards from 'components/Atoms/SVG/Icons/SvgCards';
import SvgUploadToCloud from 'components/Atoms/SVG/Icons/SvgUploadToCloud';
import SvgCheckbox from 'components/Atoms/SVG/Icons/SvgCheckbox';
import SvgCircularEconomy from 'components/Atoms/SVG/Icons/SvgCircularEconomy';

import { useSelector } from 'react-redux';

import styles from './LoadingPage.module.scss';
import titleStyles from '../../Text/Title/Title.module.scss';


const LoadingPage = (props) => {
	const storeAppLoading = useSelector((state => state.app.appLoading));

	useEffect(() => {
		window.scrollTo(0, 0);
		const body = document.getElementsByTagName('body')[0];
		body.classList.add('no-scroll');
	}, []);

	useEffect( () => () => {
		const body = document.getElementsByTagName('body')[0];
		body.classList.remove('no-scroll');
	}, []);

	let text = null;
	let icon = null;
	let titlePos = 'top';

	switch (storeAppLoading) {
		case 'default':
			icon = '';
			break;
		case 'webid':
			text = m('loadingPage.webid', 'fields');
			icon = <SvgVideoconference />;
			titlePos = 'bottom';
			break;
		case 'DAC':
			text = m('loadingPage.DAC', 'fields');
			icon = <SvgCards />;
			titlePos = 'bottom';
			break;
		case 'sendingDocuments':
			text = m('loadingPage.sendingDocuments', 'fields');
			icon = <SvgUploadToCloud />;
			titlePos = 'bottom';
			break;
		case 'postident':
			text = m('loadingPage.postident', 'fields');
			icon = <SvgCheckbox />;
			titlePos = 'bottom';
			break;
		case 'klarna':
			text = m('loadingPage.klarna', 'fields');
			icon = <SvgCircularEconomy />;
			titlePos = 'bottom';
			break;
		default:
			break;
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.inner}>
				<Container>
					<Row justifyContent="center">
						<Col xs={12} md={10} lg={8} xl={7} xxl={6} xxxl={5} textAlign="center">
							{titlePos === 'bottom' && <div className="mb--30"><LoadingSpinner icon={icon} /></div>}
							<p className={classNames(titleStyles.title, titleStyles.xl)}>
								{props.text ?? text ?? m('loadingPage.default', 'fields')}
							</p>
							{titlePos === 'top' && <div className="mt--30"><LoadingSpinner /></div>}
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

LoadingPage.propTypes = {
	text: PropTypes.string
};

export default LoadingPage;
