import React from 'react';
import { useLocation } from 'react-router-dom';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';

import Button from 'components/Atoms/Button/Button';
import CreditCalculatorMonthlyRate from './CreditCalculatorMonthlyRate';
import CreditCalculatorDesiredAmount from './CreditCalculatorDesiredAmount';
import CreditCalculatorRuntime from './CreditCalculatorRuntime';

import styles from './CreditCalculator.module.scss';
import PropTypes from 'prop-types';

const CreditCalculator = (props) => {
	const { getCurrentFlow } = useUtils();

	const location = useLocation();

	const currentFlow = getCurrentFlow();

	const isCreditIndexPage =
		location.pathname === C.FRONTEND.CREDIT ||
		location.pathname === C.FRONTEND.CREDIT + C.ROUTES.INDEX;

	return (
		<>
			<CreditCalculatorMonthlyRate />

			<CreditCalculatorDesiredAmount />

			<CreditCalculatorRuntime />

			<div className={styles.submitbuttonWrapper}>
				<Button
					onClick={props.onClose}
					tabindex={108}
				>
					{
						isCreditIndexPage
							? m('name.request', currentFlow)
							: m('next.label', 'fields')
					}
				</Button>
			</div>
		</>
	);
};

CreditCalculator.propTypes = {
	onClose: PropTypes.func
};

export default CreditCalculator;
