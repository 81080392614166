import React, { forwardRef, useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Ripple from 'components/Atoms/Ripple/Ripple';

import styles from './Button.module.scss';

export const Button = forwardRef((props, ref) => {
	const buttonRef = ref ? ref : useRef(null);

	const style = useMemo(() => {
		switch (props.buttonStyle) {
			case 'secondary':
				return styles.secondary;
			case 'link':
				return styles.link;
			case 'linkFlat':
				return styles.linkFlat;
			default:
				return styles.primary;
		}
	}, [props.buttonStyle]);

	const size = useMemo(() => {
		switch (props.buttonSize) {
			case 'xs':
				return styles.xs;
			case 's':
				return styles.s;
			case 'l':
				return styles.l;
			default:
				return styles.m;
		}
	}, [props.buttonSize]);

	const classes = useMemo(() => {
		return classNames(
			styles.button,
			style,
			size,
			props.disabled ? styles.disabled : null,
			props.loading ? styles.loading : null,
			!props.children ? styles.round : null
		);
	}, [style, size, props.disabled, props.loading]);

	const onClick = (event) => {
		props.onClick(event);
	};

	const getContent = () => {
		return (
			<>
				{props.loading ? (
					<span className={styles.loadingAnimation}>
						<span className={styles.loadingAnimationBounce1} />
						<span className={styles.loadingAnimationBounce2} />
						<span className={styles.loadingAnimationBounce3} />
					</span>
				) : (
					<>
						{props.prependIcon && (
							<span className={styles.prependIcon}>{props.prependIcon}</span>
						)}
						{props.children && <span className={styles.text}>{props.children}</span>}
						{props.appendIcon && (
							<span className={styles.prependIcon}>{props.appendIcon}</span>
						)}
					</>
				)}
				{props.buttonStyle !== 'link' &&
					props.buttonStyle !== 'linkFlat' && (
						<Ripple
							rippleRef={buttonRef}
							color={
								props.buttonStyle === 'secondary' ? '#0a4346' : '#5a1b35'
							}
						/>
					)}
			</>
		);
	};

	return (
		<div className={styles.wrapper}>
			{props.link ? (
				<a
					ref={buttonRef}
					className={classes}
					href={props.link}
					target={props.newTab ? '_blank' : null}
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
					rel="noopener noreferrer"
					aria-label={props.ariaLabel ?? null}
					aria-expanded={props.ariaExpanded ?? null}
					aria-controls={props.ariaControls ?? null}
				>
					{getContent()}
				</a>
			) : (
				<button
					ref={buttonRef}
					className={classes}
					onClick={onClick}
					type={props.buttonType ?? 'button'}
					disabled={props.disabled}
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
					aria-label={props.ariaLabel ?? null}
					aria-expanded={props.ariaExpanded ?? null}
					aria-controls={props.ariaControls ?? null}
				>
					{getContent()}
				</button>
			)}
		</div>
	);
});

Button.propTypes = {
	onClick: PropTypes.func,
	link: PropTypes.string,
	newTab: PropTypes.bool,
	buttonStyle: PropTypes.string,
	buttonSize: PropTypes.string,
	buttonType: PropTypes.string,
	prependIcon: PropTypes.any,
	appendIcon: PropTypes.any,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	tabindex: PropTypes.number,
	testId: PropTypes.string,
	ariaLabel: PropTypes.string,
	ariaExpanded: PropTypes.bool,
	ariaControls: PropTypes.string,
	children: PropTypes.any
};

export default Button;
