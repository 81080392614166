import React from 'react';
import PropTypes from 'prop-types';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';

import { useUtils } from 'utils/utils';
import { Text } from 'components/Atoms/Text';
import PhoneLink from 'components/Atoms/Partials/PhoneLink/PhoneLink';
import SvgSpeeches from 'components/Atoms/SVG/Icons/SvgSpeeches';

import styles from './ServiceHotline.module.scss';

const ServiceHotline = (props) => {
	const { getCurrentFlow } = useUtils();

	const currentFlow = getCurrentFlow();

	return (
		<div className={styles.wrapper}>
			<div aria-hidden="true">
				<SvgSpeeches />
			</div>
			<Text>
				{m(
					'serviceHotline.content1',
					[C.FRONTEND.SHORT_CREDIT, C.FRONTEND.GIRO].includes(
						currentFlow
					)
						? currentFlow
						: 'fields'
				)}{' '}
				{<PhoneLink tabindex={props.tabindex} />}{' '}
				{m(
					'serviceHotline.content2',
					[C.FRONTEND.SHORT_CREDIT, C.FRONTEND.GIRO].includes(
						currentFlow
					) ? currentFlow : 'fields'
				)}
			</Text>
		</div>
	);
};

ServiceHotline.propTypes = {
	tabindex: PropTypes.number
};

export default ServiceHotline;
