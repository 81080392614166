import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { m } from 'utils/messages/messages';

import { Link, Text } from 'components/Atoms/Text';
import SvgShare from 'components/Atoms/SVG/Icons/SvgShare';

import titleStyles from 'components/Atoms/Text/Title/Title.module.scss';

const SchufaModalContent = (props) => {
	return (
		<>
			<p className={classNames(titleStyles.title, titleStyles.s, titleStyles.mb)}>{m('acceptSchufa.popup.headline', 'fields')}</p>
			<Text mb>{m('acceptSchufa.popup.content1', 'fields')}</Text>
			<Text mb>{m('acceptSchufa.popup.content2', 'fields')}</Text>
			<Text>
				{m('acceptSchufa.popup.content3', 'fields')}{' '}
				<Link href="https://www.schufa.de/datenschutz" target="_blank" tabindex={props.startTabindex}>
					{m('acceptSchufa.popup.linkText', 'fields')}
					<SvgShare />
				</Link>
				{m('acceptSchufa.popup.content4', 'fields')}
			</Text>
		</>
	);
};

SchufaModalContent.propTypes = {
	startTabindex: PropTypes.number
};

export default SchufaModalContent;
