import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const SvgCalendar = (props) => {
	const [titleId] = useState(uuid());
	const width = 24;
	const height = 24;
	const style = { fill: props.color ? props.color : null, fillRule: 'nonzero' };
	return (
		<svg
			width={width} height={height}
			viewBox={'0 0 ' + width + ' ' + height}
			style={style}
			aria-describedby={props.title ? titleId : null}
			aria-hidden={!props.title ? true : undefined}
		>
			{props.title && titleId && (
				<title id={titleId}>{props.title}</title>
			)}
			<path d="M21.985 8.425V5.773c0-.996-.81-1.807-1.807-1.807H17.99V2.501a.5.5 0 1 0-1 0v1.465H7.01V2.501a.5.5 0 1 0-1 0v1.465H3.813c-.996 0-1.806.81-1.806 1.807v2.693C2.006 8.478 2 8.488 2 8.5c0 .012.006.022.007.034v11.65c0 .996.81 1.806 1.806 1.806h16.365c.996 0 1.807-.81 1.807-1.806V8.575c.004-.025.015-.048.015-.075 0-.027-.011-.05-.015-.075zM3.813 4.966H6.01V6.5a.5.5 0 1 0 1 0V4.966h9.98V6.5a.5.5 0 1 0 1 0V4.966h2.188c.445 0 .807.362.807.807V8H3.007V5.773c0-.445.362-.807.806-.807zM20.178 20.99H3.813a.808.808 0 0 1-.806-.806V9h17.978v11.184a.808.808 0 0 1-.807.806z" />
		</svg>
	);
};

SvgCalendar.propTypes = {
	title: PropTypes.string,
	color: PropTypes.string
};

export default SvgCalendar;
