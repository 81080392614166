import React, { useEffect } from 'react';
import classNames from 'classnames';
import C from 'constants/Constants';
import { CREDIT_AMOUNT_DEFAULT, CREDIT_AMOUNT_MAX_VALUE, CREDIT_AMOUNT_MIN_VALUE } from 'constants/Credit';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { useCreditUtils } from 'utils/credit/credit';
import { useNumberUtils } from 'utils/number/number';

import { Text } from 'components/Atoms/Text';
import { SliderInput } from '../../Atoms/Form';
import Button from '../../Atoms/Button/Button';
import SvgAdd from 'components/Atoms/SVG/Icons/SvgAdd';
import SvgClose from 'components/Atoms/SVG/Icons/SvgClose';

import { useDispatch, useSelector } from 'react-redux';
import { setIsShowRedemption, updateAmount } from 'store/App/actions';
import { setExternalCredits } from 'store/ExternalCredit/actions';

import styles from './CreditCalculator.module.scss';

const CreditCalculatorDesiredAmount = () => {
	const { getCurrentFlow } = useUtils();
	const { getSummedExternalCreditAmount } = useCreditUtils();
	const { formatMoneyValue } = useNumberUtils();

	const dispatch = useDispatch();
	const storeSidebarAnimation = useSelector((state => state.app.sidebarAnimation));
	const storeSidebarActive = useSelector((state => state.app.sidebarActive));
	const storeSidebarExtensionActive = useSelector((state => state.app.sidebarExtensionActive));
	const storeSidebarRuntimeAnimation = useSelector((state => state.app.sidebarRuntimeAnimation));
	const storeIsShowRedemption = useSelector((state => state.app.isShowRedemption));
	const storeAmount = useSelector((state => state.app.amount));
	const storeExternalCredits = useSelector((state => state.externalCredit.externalCredits));

	const currentFlow = getCurrentFlow();

	useEffect(() => {
		if (storeSidebarAnimation) {
			openRedemption();
		}
	}, [storeSidebarAnimation]);

	useEffect(() => {
		// Closes loan externalCredit slider (Ablösekredit) if the amount is 0 and user closes right sidebar
		const externalCredit = getSummedExternalCreditAmount();
		if (!storeSidebarActive && externalCredit === 0) {
			closeRedemption();
		}
	}, [storeSidebarActive]);

	const toggleRedemption = () => {
		if (storeIsShowRedemption) {
			dispatch(setExternalCredits([]));
		}
		dispatch(setIsShowRedemption(!storeIsShowRedemption));
	};

	const openRedemption = () => {
		dispatch(setIsShowRedemption(true));
	};

	const closeRedemption = () => {
		dispatch(setExternalCredits([]));
		dispatch(setIsShowRedemption(false));
	};

	const calculateTotalValue = () => {
		let wishAmount = storeAmount && !isNaN(storeAmount) ? parseInt(storeAmount) : 0;
		let externalCredit =
			storeExternalCredits && storeExternalCredits.length > 0 && !isNaN(storeExternalCredits[0].remainingBalance)
				? parseInt(storeExternalCredits[0].remainingBalance, 10)
				: 0;

		return formatMoneyValue(wishAmount + externalCredit, false);
	};

	const onChangeExternalCreditSlider = (value) => {
		let formattedValue = value ? +value : 0;
		formattedValue = formattedValue < CREDIT_AMOUNT_MAX_VALUE - getCreditValue() ? formattedValue : CREDIT_AMOUNT_MAX_VALUE - getCreditValue();
		dispatch(setExternalCredits([{ iban: C.DUMMY_IBAN, remainingBalance: formattedValue }]));
	};

	const onChangeCreditSlider = (value) => {
		let formattedValue = value ? +value : 0;
		formattedValue = formattedValue < CREDIT_AMOUNT_MAX_VALUE - getSummedExternalCreditAmount() ? formattedValue : CREDIT_AMOUNT_MAX_VALUE - getSummedExternalCreditAmount();
		if (storeAmount !== formattedValue) {
			dispatch(updateAmount(formattedValue));
		}
	};

	const getCreditValue = () => {
		return storeAmount && !isNaN(storeAmount) ? parseInt(storeAmount) : CREDIT_AMOUNT_DEFAULT;
	};

	return (
		<>
			<div
				className={classNames(styles.wishWrapper, {
					[styles.isPutBack]: storeSidebarExtensionActive || storeSidebarRuntimeAnimation
				})}
			>
				<SliderInput
					value={storeAmount ? `${storeAmount}` : `${CREDIT_AMOUNT_DEFAULT}`}
					valueFormatter={(value) => `${value ? formatMoneyValue(value, false) : 0} €`}
					setValue={onChangeCreditSlider}
					label={m('wishAmount.label', 'fields')}
					min={CREDIT_AMOUNT_MIN_VALUE}
					max={CREDIT_AMOUNT_MAX_VALUE}
					step={500}
					tabindex={102}
					tooltip={m('wishAmount.tooltip', currentFlow)}
					tooltipTabindex={101}
				/>

				<div className="text-align-center">
					<Button
						onClick={toggleRedemption}
						appendIcon={storeIsShowRedemption ? <SvgClose /> : <SvgAdd />}
						tabindex={103}
						ariaLabel={storeIsShowRedemption ? 'Ablösekrediteinstellungen schließen' : 'Ablösekrediteinstellungen öffnen'}
						ariaExpanded={storeIsShowRedemption}
						ariaControls="redemption-amount-wrapper"
					>
						{m('redemptionCredit.add', 'fields')}
					</Button>
				</div>

				{storeIsShowRedemption && (
					<div id="redemption-amount-wrapper" role="dialog" className={styles.totalWrapper}>
						<SliderInput
							value={`${getSummedExternalCreditAmount()}`}
							valueFormatter={(value) => `${value ? formatMoneyValue(value, false) : 0} €`}
							setValue={onChangeExternalCreditSlider}
							label={m('redemptionCredit.label', 'fields')}
							min={0}
							max={CREDIT_AMOUNT_MAX_VALUE}
							step={500}
							tabindex={105}
							tooltip={m('redemptionAmount.tooltip', currentFlow)}
							tooltipTabindex={104}
						/>

						<div className={styles.totalBox}>
							<div className={styles.totalBoxText}>
								<Text size="l">{m('netLoanAmount.label', 'fields')}</Text>
							</div>
							<div className={styles.totalAmount}>
								<Text size="l"><strong>{calculateTotalValue()} €</strong></Text>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
export default CreditCalculatorDesiredAmount;
