import { forwardRef, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import HelperText from 'components/Atoms/Form/HelperText/HelperText';
import Ripple from 'components/Atoms/Ripple/Ripple';
import TooltipIcon from 'components/Atoms/Tooltip/TooltipIcon/TooltipIcon';
import { v4 as uuid } from 'uuid';

import textInputStyles from '../TextInput/TextInput.module.scss';
import styles from './DateInput.module.scss';

export const DateInput = forwardRef((props, ref) => {
	const [id] = useState(uuid());
	const inputRef = ref ? ref : useRef(null);

	const onChange = (event) => {
		props.setValue(event.target.value);
	};

	return (
		<div className={textInputStyles.wrapper}>
			<div className={textInputStyles.labelWrapper}>
				<label htmlFor={id} className={textInputStyles.label}>
					{props.label}{props.required ? '*' : ''}
				</label>
				{props.tooltip && (
					<span className={textInputStyles.tooltip}>
						<TooltipIcon
							text={props.tooltip}
							tabindex={props.tooltipTabindex}
						/>
					</span>
				)}
			</div>
			<div className={textInputStyles.inputWrapper}>
				<input
					ref={inputRef}
					id={id}
					type="date"
					className={classNames(
						textInputStyles.input,
						styles.input,
						props.hasError ? textInputStyles.error : null,
						props.hasError ? 'input--error' : null
					)}
					value={props.value ?? ''}
					onChange={onChange}
					min={props.min ?? null}
					max={props.max ?? null}
					step={props.step ? `${props.step}` : null}
					disabled={props.disabled}
					required={props.required}
					onInvalid={(event) => event.preventDefault()}
					tabIndex={props.tabindex}
					data-testid={props.testId ?? null}
					aria-describedby={props.hasError ? `error-${id}` : null}
				/>
				<div className={textInputStyles.ripple}>
					<Ripple rippleRef={inputRef} color="#cccccc"/>
				</div>
			</div>
			<HelperText
				id={`error-${id}`}
				type={props.hasError ? 'error' : 'default'}
				message={props.message}
				hasIcon
			/>
		</div>
	);
});

DateInput.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	max: PropTypes.string,
	min: PropTypes.string,
	step: PropTypes.number,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tabindex: PropTypes.number,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltipTabindex: PropTypes.number,
	testId: PropTypes.string
};

export default DateInput;
